.panel-primary > .panel-heading {
  background-image: none;
  background-color: #3f51b5;
}

.panel-heading {
  padding: 0.5rem 1rem;
  font: normal 0.875rem/1 Roboto, Helvetica, Arial, Segoe UI, sans-serif;
  border-radius: 0;
}

.btn:focus {
  outline: none;
}

.btn:active:focus {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

.ajaxloaddingcart_mini {
  background: url(../img/ajax-loader-mini.gif) no-repeat center transparent;
  opacity: 0.8;
  height: 50px;
  width: 50px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
}

.was_added_to_cart {
  display: inline-block;
  width: 100%;
  color: red;
  font-size: 10px;
}

#DOMWindow p,
.defaultDOMWindow.modal p,
.errorDOMWindow.modal p,
.deleteDOMWindow.modal p {
  text-align: center;
  line-height: 1.8;
  margin: 5px 0;
  padding: 0;
}

.modal_header {
  text-decoration: underline;
  font-size: 18px;
  color: #c00;
}

.modal_delete {
  display: block;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  color: #c00;
  margin-top: 30px;
}

.modal_text {
  font-size: 16px;
}

.modal_summ {
  white-space: nowrap;
}

.modal_to_cart a,
.modal_checkout a {
  font-size: 16px;
  text-decoration: none !important;
  background-color: #43910f;
  color: #fff !important;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0 0 1px #000;
  -webkit-box-shadow: 0 0 1px #000;
  box-shadow: 0 0 1px #000;
  margin: 0 5px;
  padding: 5px 10px;
}

.modal_to_cart a:hover,
.modal_checkout a:hover {
  -moz-box-shadow: 0 0 3px #000;
  -webkit-box-shadow: 0 0 3px #000;
  box-shadow: 0 0 3px #000;
}

.modal_anchor a {
  white-space: nowrap;
}

.modal_anchor.red .modal_to_cart a,
.modal_anchor.red .modal_checkout a {
  background-color: #e92a1e;
}

.modal_anchor.orange .modal_to_cart a,
.modal_anchor.orange .modal_checkout a {
  background-color: #f3a016;
}

.modal_anchor.green .modal_to_cart a,
.modal_anchor.green .modal_checkout a {
  background-color: #4caf50;
}

.modal_anchor.blue .modal_to_cart a,
.modal_anchor.blue .modal_checkout a {
  background-color: #099dff;
}

.modal_anchor.grey .modal_to_cart a,
.modal_anchor.grey .modal_checkout a {
  background-color: #444;
}

.modal_anchor.dark .modal_to_cart a,
.modal_anchor.dark .modal_checkout a {
  background-color: #222;
}

.modal_err {
  display: block;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  color: #c00;
  margin: 10px 0 0;
}

.notselected {
  background-color: #ccc;
}

.defaultDOMWindow.modal {
  padding: 10px;
}

.collapse {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  -moz-transition: height 0.35s ease;
  -o-transition: height 0.35s ease;
  transition: height 0.35s ease;
  display: none;
}

.collapse.in {
  height: auto;
  display: block;
}

.highlight {
  background: url(../img/bgattr.gif);
}

.mycart_wrapp {
  display: block;
  position: relative;
  min-width: 80px;
  min-height: 80px;
  max-width: 300px;
  z-index: 999;
  line-height: 1.3;
  margin: 7px 0;
}

div.hidden_for_cart {
  display: none !important;
}

.mycart_mini_txt:not(.externbootstrap) {
  width: 66px;
  height: 66px;
  display: block;
  position: relative;
  -moz-box-shadow: 0 0 5px #000;
  -webkit-box-shadow: 0 0 5px #000;
  box-shadow: 0 0 5px #000;
  -moz-border-radius: 55px;
  -webkit-border-radius: 55px;
  border-radius: 55px;
}

.mycart_wrapp.green .mycart_mini_txt {
  background: url(../img/cart_mini_green.png) 0 0 transparent no-repeat;
}

.wishlist_view .mycart_wrapp.green .mycart_mini_txt {
  background: url(../img/wlist_mini_green.png) 0 0 transparent no-repeat;
}

.mycart_wrapp.grey .mycart_mini_txt {
  background: url(../img/cart_mini_grey.png) 0 0 transparent no-repeat;
}

.wishlist_view .mycart_wrapp.grey .mycart_mini_txt {
  background: url(../img/wlist_mini_grey.png) 0 0 transparent no-repeat;
}

.mycart_wrapp.dark .mycart_mini_txt {
  background: url(../img/cart_mini_dark.png) 0 0 transparent no-repeat;
}

.wishlist_view .mycart_wrapp.dark .mycart_mini_txt {
  background: url(../img/wlist_mini_dark.png) 0 0 transparent no-repeat;
}

.mycart_wrapp.red .mycart_mini_txt {
  background: url(../img/cart_mini_red.png) 0 0 transparent no-repeat;
}

.wishlist_view .mycart_wrapp.red .mycart_mini_txt {
  background: url(../img/wlist_mini_red.png) 0 0 transparent no-repeat;
}

.mycart_wrapp.orange .mycart_mini_txt {
  background: url(../img/cart_mini_orange.png) 0 0 transparent no-repeat;
}

.wishlist_view .mycart_wrapp.orange .mycart_mini_txt {
  background: url(../img/wlist_mini_orange.png) 0 0 transparent no-repeat;
}

.mycart_wrapp.blue .mycart_mini_txt {
  background: url(../img/cart_mini_blue.png) 0 0 transparent no-repeat;
}

.wishlist_view .mycart_wrapp.blue .mycart_mini_txt {
  background: url(../img/wlist_mini_blue.png) 0 0 transparent no-repeat;
}

.mycart_mini_txt a {
  color: #fff !important;
  font-size: 20px;
  text-align: center;
  text-decoration: none !important;
  padding-top: 8px;
  width: 66px;
  height: 60px;
  display: block;
  position: relative;
}

.mycart_mini_txt a:hover {
  background: none !important;
}

.mycart_wrapp.green .extern_content a,
.mycart_wrapp.green .extern_bottom a {
  color: #65b923;
}

.mycart_wrapp.red .extern_content a,
.mycart_wrapp.red .extern_bottom a {
  color: red;
}

.mycart_wrapp.orange .extern_content a,
.mycart_wrapp.orange .extern_bottom a {
  color: #ff5c00;
}

.mycart_wrapp.blue .extern_content a,
.mycart_wrapp.blue .extern_bottom a {
  color: #099dff;
}

.mycart_wrapp.green .midileft_wrap,
.mycart_wrapp.red .midileft_wrap,
.mycart_wrapp.orange .midileft_wrap,
.mycart_wrapp.blue .midileft_wrap {
  background-color: #444;
}

.mycart_wrapp.grey .midileft_wrap,
.mycart_wrapp.dark .midileft_wrap {
  background-color: #fff;
}

.midileft_wrap {
  position: absolute;
  left: 10px;
  width: 66px !important;
  height: 66px !important;
  border: 1px solid #ccc;
  -moz-box-shadow: 0 0 5px #000;
  -webkit-box-shadow: 0 0 5px #000;
  box-shadow: 0 0 5px #000;
  -moz-border-radius: 55px;
  -webkit-border-radius: 55px;
  border-radius: 55px;
  overflow: hidden;
  z-index: 9999;
  padding: 0 !important;
}

.midileft_wrap:hover {
  width: 300px !important;
  overflow: visible;
}

.midileft_wrap:hover.midileft_wrap .midileft_text,
.midileft_wrap:hover.midileft_wrap .gotocart {
  display: inline-block;
}

.midileft_wrap .mycart_mini_txt {
  float: left;
}

.mycart_wrapp.green .midileft_wrap .midileft_text,
.mycart_wrapp.red .midileft_wrap .midileft_text,
.mycart_wrapp.orange .midileft_wrap .midileft_text,
.mycart_wrapp.blue .midileft_wrap .midileft_text {
  color: #fff !important;
}

.mycart_wrapp.grey .midileft_wrap .midileft_text,
.mycart_wrapp.dark .midileft_wrap .midileft_text {
  color: #555;
}

.midileft_wrap .midileft_text {
  display: none;
  width: 200px;
  font-size: 12px;
  text-align: center;
  margin: 7px 0 7px 10px;
}

.midileft_wrap .midileft_text.empty_cart {
  text-align: center;
  margin: 25px 0 0;
}

.midileft_wrap .gotocart {
  display: none;
  width: 200px;
  font-size: 10px;
  text-align: center;
  margin: 0 0 0 10px;
}

.midileft_wrap .gotocart.empty_cart {
  display: none !important;
}

.mycart_wrapp.green .midileft_wrap .gotocart a {
  color: #65b923;
}

.mycart_wrapp.red .midileft_wrap .gotocart a {
  color: red;
}

.mycart_wrapp.orange .midileft_wrap .gotocart a {
  color: #ff5c00;
}

.mycart_wrapp.blue .midileft_wrap .gotocart a {
  color: #099dff;
}

.midileft_wrap .gotocart a {
  text-decoration: none;
}

.midileft_wrap .gotocart a:hover {
  border-bottom: 1px dotted #fff;
}

.mycart_wrapp.green .midiright_wrap,
.mycart_wrapp.red .midiright_wrap,
.mycart_wrapp.orange .midiright_wrap,
.mycart_wrapp.blue .midiright_wrap {
  background-color: #444;
}

.mycart_wrapp.grey .midiright_wrap,
.mycart_wrapp.dark .midiright_wrap {
  background-color: #fff;
}

.midiright_wrap {
  position: absolute;
  right: 10px;
  width: 66px !important;
  height: 66px !important;
  border: 1px solid #ccc;
  -moz-box-shadow: 0 0 5px #000;
  -webkit-box-shadow: 0 0 5px #000;
  box-shadow: 0 0 5px #000;
  -moz-border-radius: 55px;
  -webkit-border-radius: 55px;
  border-radius: 55px;
  overflow: hidden;
  z-index: 9999;
  padding: 0 !important;
}

.midiright_wrap:hover {
  width: 300px !important;
  overflow: visible;
}

.midiright_wrap:hover.midiright_wrap .midiright_text,
.midiright_wrap:hover.midiright_wrap .gotocart {
  display: inline-block;
}

.midiright_wrap .mycart_mini_txt {
  float: right;
}

.mycart_wrapp.green .midiright_wrap .midiright_text,
.mycart_wrapp.red .midiright_wrap .midiright_text,
.mycart_wrapp.orange .midiright_wrap .midiright_text,
.mycart_wrapp.blue .midiright_wrap .midiright_text {
  color: #fff !important;
}

.mycart_wrapp.grey .midiright_wrap .midiright_text,
.mycart_wrapp.dark .midiright_wrap .midiright_text {
  color: #555;
}

.midiright_wrap .midiright_text {
  display: none;
  width: 200px;
  font-size: 12px;
  text-align: center;
  margin: 7px 0 7px 30px;
}

.midiright_wrap .midiright_text.empty_cart {
  text-align: center;
  margin: 25px 0 0;
}

.midiright_wrap .gotocart {
  display: none;
  width: 200px;
  font-size: 10px;
  text-align: center;
  margin: 0 0 0 30px;
}

.midiright_wrap .gotocart.empty_cart {
  display: none !important;
}

.mycart_wrapp.green .midiright_wrap .gotocart a {
  color: #65b923 !important;
}

.mycart_wrapp.red .midiright_wrap .gotocart a {
  color: red !important;
}

.mycart_wrapp.orange .midiright_wrap .gotocart a {
  color: #ff5c00 !important;
}

.mycart_wrapp.blue .midiright_wrap .gotocart a {
  color: #099dff !important;
}

.midiright_wrap .gotocart a {
  text-decoration: none;
}

.midiright_wrap .gotocart a:hover {
  border-bottom: 1px dotted #fff;
}

.mycart_mini_txt.extern a {
  width: 66px;
  height: 34px;
}

.mycart_mini_txt.extern .arrow_down {
  display: none;
  text-align: center;
  color: #fff;
  padding-top: 10px;
  font-size: 8px;
  cursor: pointer;
}

.mycart_mini_txt.externright {
  float: right;
}

.mycart_wrapp:hover .extern_wrap,
.mycart_wrapp:hover .mycart_mini_txt.extern .arrow_down,
.mycart_wrapp:focus .extern_wrap,
.mycart_wrapp:focus .mycart_mini_txt.extern .arrow_down {
  display: block;
}

.mycart_wrapp:hover .extern_wrap.dnone,
.mycart_wrapp:focus .extern_wrap.dnone {
  display: none;
}

.extern_wrap {
  position: absolute;
  display: none;
  background-color: #fff;
  width: 100%;
  max-width: 320px;
  min-width: 300px;
  margin-top: 7px;
  border: 1px solid #ccc;
  -moz-box-shadow: 0 1px 5px #000;
  -webkit-box-shadow: 0 1px 5px #000;
  box-shadow: 0 1px 5px #000;
  z-index: 9999;
}

.extern_wrap.externright {
  right: 10px;
}

div.extern_wrap div {
  margin: 0 !important;
  padding: 0 !important;
}

.extern_row {
  position: relative;
  display: block;
  border-bottom: 1px dotted #ccc;
  border-right: 1px dotted #ccc;
  border-left: 1px dotted #ccc;
  min-height: 100px;
  text-align: left;
  margin: 0;
  padding: 0;
}

.extern_row span {
  padding: 7px 5px;
}

.extern_row span.pict {
  position: relative;
  display: block;
  width: 100px;
}

.extern_row span.pict img {
  max-width: 90px;
}

.defaultDOMWindow .modal-bottom {
  border-top: 1px dotted #ccc;
}

.extern_row div {
  display: inline-block;
}

.extern_row div.block {
  display: block;
}

.extern_row div.pict {
  vertical-align: top;
}

.extern_row span.name {
  display: block;
  max-width: 155px;
  font-size: 12px;
  color: #666;
  left: 110px;
  top: 0;
}

.extern_row span.qtty,
.extern_row span.summ {
  font-size: 14px;
  bottom: 5px;
}

.extern_row span.qtty {
  color: #777;
  left: 110px;
  width: 25%;
  max-width: 75px;
}

.extern_row span.summ {
  color: #777;
  right: 10px;
  white-space: nowrap;
}

.extern_wrap .total {
  display: block;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  color: #333;
  padding: 7px;
}

.extern_wrap .summ_total {
  color: #ee3535;
  white-space: nowrap;
}

.extern_wrap .gotocart {
  display: block;
  text-align: center;
  padding: 0 7px 7px;
}

.extern_wrap .gotocart a {
  display: block;
  line-height: 1.8;
  text-decoration: none !important;
}

.extern_bottom.empty_cart {
  display: none;
}

.extern_bottom {
  background-color: #f0f0f0;
}

.extern_wrap .extern_empty,
.extern_wrap .extern_empty_jq {
  display: block;
  padding: 7px;
}

.desription-bottom.block {
  position: relative;
}

.minicart_attr_wrap,
.minicart_ef_wrap {
  display: inline-block;
  font-size: 11px;
  cursor: pointer;
  margin: 0 0 0 5px;
}

.minicart_attr_wrap:hover .minicart_attr_list,
.minicart_ef_wrap:hover .minicart_ef_list {
  display: block;
}

.minicart_attr_list {
  display: none;
  position: absolute;
  left: 0;
  top: 25px;
  width: 275px;
  border: 1px solid #ccc;
  font-size: 10px;
  background-color: #f0f0f0;
  z-index: 9998;
}

.minicart_ef_list {
  display: none;
  position: absolute;
  left: 0;
  top: 25px;
  width: 275px;
  border: 1px solid #ccc;
  font-size: 10px;
  background-color: #f0f0f0;
  z-index: 9999;
}

.minicart_attr_wrap.attr_length_0 {
  display: none;
}

.extern_row span.delete {
  position: absolute;
  display: block;
  max-width: 155px;
  background-color: #333;
  top: 10px;
  right: 10px;
  z-index: 999;
  margin: 0;
  padding: 0 3px;
}

.extern_row span.delete a {
  text-decoration: none !important;
  color: #fff;
}

.extern_row span.delete a:hover {
  color: #ee3535;
}

.extern_row span.qtybtn {
  position: absolute;
  display: block;
  min-width: 10px;
  max-width: 13px;
  color: #fff;
  font-size: 14px;
  top: 30px;
  right: 10px;
  z-index: 999;
  margin: 0;
  padding: 0;
}

.extern_row span:hover {
  cursor: pointer;
}

.show_quantity_buttons .extern_row span.qtty {
  padding: 4px 0;
}

.extern_row .minus_quantity,
.extern_row .plus_quantity {
  display: inline-block;
  background-color: #f0f0f0;
  color: #777;
  font-size: 14px;
  border: 1px solid #ccc;
  text-align: center;
  margin: 0 1px;
  padding: 0 3px;
}

.extern_row input.input_quantity {
  position: relative;
  max-width: 24px;
  display: inline-block;
  border-radius: 0;
  border: 1px solid #ccc;
  background-color: #fff;
  color: #777;
  font-size: 14px;
  text-align: center;
  margin: 0 0 3px;
  padding: 0;
}

.externtwo_wrapp {
  width: 300px;
  min-height: 30px;
  box-shadow: 0 0 5px #000;
  -moz-box-shadow: 0 0 5px #000;
  -webkit-box-shadow: 0 0 5px #000;
}

.mycart_wrapp.externtwo_wrapp:hover {
  box-shadow: 0 0 7px #000 !important;
}

.externtwo_wrapp .mycart_mini_txt {
  box-shadow: 0 0 0 #000;
  -moz-box-shadow: 0 0 0 #000;
  -webkit-box-shadow: 0 0 0 #000;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  float: left;
  color: #fff !important;
  font-size: 12px;
  text-align: center;
  width: 265px;
  height: 30px;
  line-height: 30px;
  padding: 0;
}

.externtwo_wrapp .mycart_mini_txt:hover {
  cursor: pointer;
}

.mycart_wrapp.externtwo_wrapp.red .mycart_mini_txt {
  background: url(../img/arrow_red.png) 250px 0 #e92a1e no-repeat;
}

.mycart_wrapp.externtwo_wrapp.green .mycart_mini_txt {
  background: url(../img/arrow_green.png) 250px 0 #80c73f no-repeat;
}

.mycart_wrapp.externtwo_wrapp.orange .mycart_mini_txt {
  background: url(../img/arrow_orange.png) 250px 0 #f3a016 no-repeat;
}

.mycart_wrapp.externtwo_wrapp.blue .mycart_mini_txt {
  background: url(../img/arrow_blue.png) 250px 0 #099dff no-repeat;
}

.mycart_wrapp.externtwo_wrapp.grey .mycart_mini_txt {
  background: url(../img/arrow_grey.png) 250px 0 #8b8b8b no-repeat;
}

.mycart_wrapp.externtwo_wrapp.dark .mycart_mini_txt {
  background: url(../img/arrow_dark.png) 250px 0 #141414 no-repeat;
}

.externtwo_wrapp .mini_cart_img a {
  text-decoration: none !important;
}

.externtwo_wrapp .mini_cart_img img {
  border: none !important;
}

.externtwo_wrapp .extern_wrap {
  margin-top: 0 !important;
  border: 0;
  background-color: #f9f9f9;
}

.extern_wrap.dblock {
  display: block;
  position: relative;
}

.externtwo_wrapp .extern_wrap.dblock {
  display: block;
  position: relative;
}

.externbootstrap_wrapp {
  display: block;
  background-color: #252a2f;
  width: 100%;
  height: auto;
  min-width: 300px;
  min-height: inherit;
  max-width: 320px;
  line-height: 25px;
  margin: 0;
}

.externbootstrap_wrapp:hover {
  box-shadow: 0 0 7px #000 !important;
}

.mycart_wrapp.externbootstrap_wrapp.red .mycart_mini_txt,
.mycart_wrapp.externbootstrap_wrapp.green .mycart_mini_txt,
.mycart_wrapp.externbootstrap_wrapp.orange .mycart_mini_txt,
.mycart_wrapp.externbootstrap_wrapp.blue .mycart_mini_txt,
.mycart_wrapp.externbootstrap_wrapp.grey .mycart_mini_txt,
.mycart_wrapp.externbootstrap_wrapp.dark .mycart_mini_txt {
  background-image: none;
  background-color: #252a2f;
}

.externbootstrap_wrapp .mini_cart_img {
  display: inline-block;
  font-size: 25px;
  border: none !important;
  line-height: 25px;
  margin: 0;
  padding: 15px 20px 15px 10px;
}

.externbootstrap_wrapp .mini_cart_img a {
  display: inherit;
  width: auto;
  height: auto;
  text-decoration: none !important;
  color: #252a2f !important;
}

.mycart_wrapp.externbootstrap_wrapp.red .mini_cart_img {
  background-image: none;
  background-color: #e92a1e;
}

.mycart_wrapp.externbootstrap_wrapp.green .mini_cart_img {
  background-image: none;
  background-color: #80c73f;
}

.mycart_wrapp.externbootstrap_wrapp.orange .mini_cart_img {
  background-image: none;
  background-color: #f3a016;
}

.mycart_wrapp.externbootstrap_wrapp.blue .mini_cart_img {
  background-image: none;
  background-color: #099dff;
}

.mycart_wrapp.externbootstrap_wrapp.grey .mini_cart_img {
  background-image: none;
  background-color: #8b8b8b;
}

.mycart_wrapp.externbootstrap_wrapp.dark .mini_cart_img {
  background-image: none;
  background-color: #252a2f;
}

.externbootstrap_wrapp .mycart_mini_txt {
  display: inline-block;
  -moz-box-shadow: 0 0 0 #000;
  -webkit-box-shadow: 0 0 0 #000;
  box-shadow: 0 0 0 #000;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  color: #fff !important;
  height: auto;
  line-height: 25px;
  margin: 0;
  padding: 0 7px;
}

.externbootstrap_wrapp.emptycart .mycart_mini_txt {
  padding-top: 15px;
}

.externbootstrap_wrapp .mycart_mini_txt:hover {
  cursor: pointer;
}

.externbootstrap_wrapp .extern_wrap {
  margin-top: 0 !important;
  border: 0;
  background-color: #f9f9f9;
}

.externbootstrap_wrapp .down-click {
  color: #fff;
  font-size: 10px;
  cursor: pointer;
  padding: 15px 0;
}

.externbootstrap_wrapp .extern_wrap.dblock {
  display: block;
  position: relative;
}

.externbootstrap_wrapp .extern_wrap.dblock.dnone {
  display: none;
}

.qttyobj {
  font-size: 10px;
  padding: 0 3px;
  border: 1px solid #666;
  border-radius: 10px;
  text-decoration: none;
  text-align: center;
  min-width: 10px;
}

.dblock {
  display: block;
}

.dnone {
  display: none;
}

.dnone-force {
  dsiplay: none !important;
}

.clear {
  clear: both;
}

.min_view {
  position: relative;
}

.externbootstrap_wrapp.wrapp_min,
.mycart_wrapp.externbootstrap_wrapp.wrapp_min[class*=" "] .mini_cart_img {
  position: relative;
  display: inline-block;
  min-width: inherit;
  max-width: inherit;
  max-height: inherit;
  width: inherit;
  height: inherit;
  background-color: transparent;
  padding: 0;
  margin: 0;
}

.externbootstrap_wrapp.wrapp_min:hover {
  box-shadow: none !important;
}

.mycart_wrapp.externbootstrap_wrapp.wrapp_min .mycart_mini_txt {
  padding: 3px 6px;
  box-shadow: none;
  border: 0;
  border-radius: 10px;
  line-height: 1;
  opacity: 0.8;
  position: absolute;
  top: -10px;
  right: -10px;
}

.mycart_wrapp.externbootstrap_wrapp.wrapp_min.red .mycart_mini_txt,
.mycart_wrapp.red .gotocart :last-child {
  background-color: #e92a1e;
  color: #fff;
}

.mycart_wrapp.externbootstrap_wrapp.wrapp_min.orange .mycart_mini_txt,
.mycart_wrapp.orange .gotocart :last-child {
  background-color: #f3a016;
  color: #fff;
}

.mycart_wrapp.externbootstrap_wrapp.wrapp_min.green .mycart_mini_txt,
.mycart_wrapp.green .gotocart :last-child {
  background-color: #65b923;
  color: #fff;
}

.mycart_wrapp.externbootstrap_wrapp.wrapp_min.blue .mycart_mini_txt,
.mycart_wrapp.blue .gotocart :last-child {
  background-color: #099dff;
  color: #fff;
}

.mycart_wrapp.externbootstrap_wrapp.wrapp_min.grey .mycart_mini_txt,
.mycart_wrapp.grey .gotocart :last-child {
  background-color: #444;
  color: #fff;
}

.mycart_wrapp.externbootstrap_wrapp.wrapp_min.dark .mycart_mini_txt,
.mycart_wrapp.dark .gotocart :last-child {
  background-color: #000;
  color: #fff;
}

.mycart_wrapp.grey .midiright_wrap .gotocart a,
.mycart_wrapp.grey .midileft_wrap .gotocart a,
.mycart_wrapp.dark .midiright_wrap .gotocart a,
.mycart_wrapp.dark .midileft_wrap .gotocart a {
  color: #f00;
}

.externbootstrap_wrapp.wrapp_min .down-click {
  font-size: inherit;
  padding: 0;
}

.externbootstrap_wrapp.wrapp_min .extern_wrap.dblock {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
}

.externbootstrap_wrapp.wrapp_min .mini_cart_img a {
  color: #444 !important;
}

.mycart_wrapp .extern_bottom a.checkout,
.wishlist_view .mycart_wrapp .extern_bottom a {
  color: #fff;
}

.mycart_wrapp .midiright_wrap .gotocart a,
.mycart_wrapp .midileft_wrap .gotocart a {
  background-color: transparent !important;
}

.collapse {
  display: none;
}

@font-face {
  font-family: "fontello";
  src: url("../font/fontello.eot?80219583");
  src: url("../font/fontello.eot?80219583#iefix") format("embedded-opentype"), url("../font/fontello.woff?80219583") format("woff"), url("../font/fontello.ttf?80219583") format("truetype"), url("../font/fontello.svg?80219583#fontello") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-brooksus"]:before,
[class*=" icon-brooksus"]:before {
  font-family: "fontello svg", "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
}

.icon-brooksus-home:before {
  content: "\21";
}

.icon-brooksus-user:before {
  content: "\22";
}

.icon-brooksus-lock:before {
  content: "\23";
}

.icon-brooksus-export:before {
  content: "\26";
}

.icon-brooksus-plus-circled:before {
  content: "\2a";
}

.icon-brooksus-th-list:before {
  content: "\31";
}

.icon-brooksus-truck:before {
  content: "\33";
}

.icon-brooksus-spinner:before {
  content: "\37";
}

.icon-brooksus-sun:before {
  content: "\38";
}

.icon-brooksus-ok-squared:before {
  content: "\3e";
}

.icon-brooksus-help-circled:before {
  content: "\45";
}

.icon-brooksus-ok:before {
  content: "\47";
}

.icon-brooksus-cancel:before {
  content: "\49";
}

.icon-brooksus-cancel-circled:before {
  content: "\4a";
}

.icon-brooksus-minus-circled:before {
  content: "\4b";
}

.icon-brooksus-trash:before {
  content: "\4c";
}

.icon-brooksus-mail-alt:before {
  content: "\4d";
}

.icon-brooksus-mail:before {
  content: "\4e";
}

.icon-brooksus-search:before {
  content: "\53";
}

.icon-brooksus-th:before {
  content: "\59";
}

.icon-brooksus-plus:before {
  content: "\5d";
}

.icon-brooksus-minus:before {
  content: "\5e";
}

.icon-brooksus-key:before {
  content: "\5f";
}

.icon-brooksus-zoom-in:before {
  content: "\64";
}

.icon-brooksus-zoom-out:before {
  content: "\65";
}

.icon-brooksus-resize-full-alt:before {
  content: "\68";
}

.icon-brooksus-chart-bar:before {
  content: "\76";
}

.icon-brooksus-arrows-cw:before {
  content: "\e001";
}

.icon-brooksus-up-open:before {
  content: "\e005";
}

.icon-brooksus-right-open:before {
  content: "\e006";
}

.icon-brooksus-down-open:before {
  content: "\e007";
}

.icon-brooksus-left-open:before {
  content: "\e008";
}

.icon-brooksus-angle-up:before {
  content: "\e009";
}

.icon-brooksus-angle-right:before {
  content: "\e00a";
}

.icon-brooksus-angle-down:before {
  content: "\e00b";
}

.icon-brooksus-angle-left:before {
  content: "\e00c";
}

.icon-brooksus-up-dir:before {
  content: "\e00f";
}

.icon-brooksus-down-dir:before {
  content: "\e011";
}

.icon-brooksus-left-dir:before {
  content: "\e012";
}

.icon-brooksus-basket:before {
  content: "\e019";
}

.icon-brooksus-link-ext:before {
  content: "\e024";
}

.icon-brooksus-plus-squared-alt:before {
  content: "\e215";
}

.icon-brooksus-minus-squared-alt:before {
  content: "\e216";
}

.icon-brooksus-cancel-circled2:before {
  content: "\e217";
}

.icon-brooksus-ok-circled:before {
  content: "\e218";
}

.icon-brooksus-ok-circled2:before {
  content: "\e219";
}

.icon-brooksus-help:before {
  content: "\e222";
}

.icon-brooksus-sitemap:before {
  content: "\e244";
}

.icon-brooksus-phone:before {
  content: "\e261";
}

.icon-brooksus-lock-open-alt:before {
  content: "\e267";
}

.icon-brooksus-heart:before {
  content: "\e277";
}

.icon-brooksus-heart-empty:before {
  content: "\e278";
}

.icon-brooksus-rouble:before {
  content: "\e286";
}

.icon-brooksus-right-dir:before {
  content: "\e812";
}

.icon-brooksus-link-ext-alt:before {
  content: "\e82b";
}

.icon-brooksus-twitter:before {
  content: "\e83c";
}

.icon-brooksus-vkontakte:before {
  content: "\e83d";
}

.icon-brooksus-facebook:before {
  content: "\e83e";
}

.icon-brooksus-gplus:before {
  content: "\e83f";
}

.icon-brooksus-skype:before {
  content: "\e841";
}

.icon-brooksus-youtube:before {
  content: "\e842";
}

.animate-spin {
  -moz-animation: spin 2s infinite linear;
  -o-animation: spin 2s infinite linear;
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
  display: inline-block;
}

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@-webkit-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@-o-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@-ms-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@-webkit-keyframes fadeIn {
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  100% {
    opacity: 1;
  }
}

.header .topbar__nav {
  padding: 0;
}

.header .topbar__menu .nav-link:hover {
  background: transparent;
  color: #3f51b5;
}

.header .cart__nav {
  display: flex;
  justify-content: flex-end;
}

.header .cart__nav .nav-link {
  background-color: transparent;
}

.header .cart__nav .nav-link:hover {
  color: #3f51b5;
}

.header .nav .nav-link {
  font-size: 0.875rem;
  line-height: 1;
}

.mainmenu .navbar {
  margin-bottom: 0;
  min-height: 0;
}

.mainmenu .navbar-nav > li {
  position: relative;
}

.mainmenu .navbar-nav > li:not(:last-child) {
  border-right: 1px solid rgba(255, 255, 255, 0.5);
}

.mainmenu .navbar-nav > li > a {
  display: block;
  font-size: 0.875rem;
  line-height: 1.3;
  padding: 0.5rem 0;
  padding: 0.5rem 1rem;
}

.mainmenu .navbar-nav > li > a:hover,
.mainmenu .navbar-nav > li > a:focus {
  color: white;
  background-color: #32408f;
}

.mainmenu .navbar-nav .dropdown:hover .dropdown-menu {
  display: block;
}

.mainmenu .navbar-nav .dropdown-menu {
  background-color: white;
}

.mainmenu .navbar-nav .dropdown-menu a {
  padding: 0.375rem 0.75rem;
  display: block;
  color: #32408f;
}

.mainmenu .navbar-nav .dropdown-menu a:hover,
.mainmenu .navbar-nav .dropdown-menu a:focus {
  color: white;
  background-color: #3f51b5;
}

table.jshop {
  border-collapse: collapse;
  border: 0;
  width: 100%;
}

#jshop_menu_order {
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
}

td.jshop_order_step {
  text-align: center;
  vertical-align: middle;
  padding: 5px 2px;
}

.jshop_order_step {
  vertical-align: top;
  text-decoration: underline;
  font-size: 12px;
}

.table_order_list {
  background-color: #f8f8f9;
  border: 1px solid #333;
  width: 100%;
}

img.jshop_img_thumb {
  text-align: center;
  border: 1px solid gray;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  padding: 1px;
}

.jshop label {
  display: inline;
  margin: 0;
}

div.jshop_prod_description {
  padding-top: 10px;
  padding-bottom: 10px;
}

div.jshop_prod_attributes {
  margin: 0;
  padding: 0 0 10px;
}

.list_product.toList div.jshop_prod_attributes,
.list_product.toSlim div.jshop_prod_attributes {
  text-align: right;
}

td.jshop_img_description {
  vertical-align: top;
  text-align: left;
  padding-left: 10px;
}

.jshop_subtotal .value {
  text-align: right;
  white-space: nowrap;
}

.jshop_subtotal td.name {
  width: 100%;
  padding-right: 5px;
}

.jshop_subtotal td {
  padding: 5px 10px 5px 5px;
  text-align: right;
}

.jshop_subtotal tr:first-child td {
  border-top: 0;
}

.jshop .total {
  font-weight: 700;
}

.related_header {
  margin-top: 20px;
  border-bottom: 1px solid #999;
  font-weight: 700;
}

.td_1 {
  text-align: left;
  font-weight: 700;
}

.td_2 {
  text-align: right;
  font-weight: 700;
}

.jshop_pagination {
  text-align: center;
}

.jshop .product_list_hr {
  height: 1px;
  border-top: 1px solid #aaa;
  margin-bottom: 10px;
}

div.jshop_register,
div.jshop_login {
  text-align: left;
  padding: 10px 10px 10px 0;
}

.editaccount_block .jshop_register .name {
  width: 165px;
}

span.small_header {
  padding-bottom: 3px;
  display: block;
  font-size: 13px;
  font-weight: 700;
  margin: 0;
}

span.jshop_error {
  color: red;
  font-weight: 700;
  font-size: 14px;
  display: block;
}

span.text_pay_without_reg {
  display: block;
  padding-bottom: 10px;
}

a.policy {
  text-transform: uppercase;
  color: #000;
  font-weight: 700;
}

.jshop_payment_method {
  padding-left: 20px;
}

.active_step {
  font-weight: 700;
  vertical-align: middle;
  margin: 0;
  padding: 0;
}

#order_add_info {
  height: 70px;
  width: 100%;
}

.rp-brooksite input.btn.list-btn.finish_registration {
  padding: 15px 30px;
  max-width: inherit;
  text-transform: uppercase;
  margin-top: 15px;
}

p.jshop_cart_extra_field,
p.jshop_cart_attribute {
  padding-left: 10px;
  font-weight: 700;
  font-style: italic;
  font-size: 11px;
  margin-top: 1px;
  margin-bottom: 4px;
}

.jshop_price {
  color: #1f1f1f;
}

.jshop_list_product .base_price,
.jshop_list_product .product .name a {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

.jshop_list_product .product_grid_item:hover .base_price,
.jshop_list_product .product_grid_item:hover .name a,
.jshop_list_product .list-row:hover .name a,
.jshop_list_product .slim-row:hover .name a {
  white-space: normal;
}

span.attr {
  font-weight: 700;
  font-style: italic;
  font-size: 10px;
}

span.review {
  font-weight: 700;
  margin-top: 20px;
  display: block;
}

.review_text_not_login {
  margin-top: 10px;
  font-style: italic;
}

textarea.jshop {
  border: 1px solid #b0b1b2;
}

tr.reviews td {
  padding-bottom: 15px;
}

td.reviews {
  padding-left: 45px;
}

#mxcpr {
  padding-top: 20px;
  text-align: right;
  display: block;
}

#mxcpr a {
  font-size: 9px;
}

.jshop_code_prod,
.jshop_manufacturer_prod {
  color: #999;
  font-weight: 400;
  font-size: 12px;
}

.jshop .shippingfree {
  text-align: right;
  margin-top: 3px;
}

.jshop .price_prod_qty_list .qty_line {
  padding-left: 5px;
  padding-right: 5px;
}

.jshop .price_prod_qty_list .qty_price {
  padding-left: 5px;
  font-weight: 700;
}

.jshop .price_prod_qty_list .qty_price span.per_piece {
  font-weight: 400;
}

.jshop span.old_price {
  text-decoration: line-through;
  font-style: normal;
}

.jshop .list_product_demo {
  padding-top: 10px;
}

.jshop .list_product_demo .download {
  padding-left: 5px;
}

.jshop .not_available {
  color: red;
  margin-top: 10px;
}

.jshop_list_product .block_product {
  position: relative;
}

.product_grid_item {
  position: relative;
  background: #fff;
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
  width: 100%;
}

.product_grid_item:hover .product_grid_item_container,
.product_grid_item:focus .product_grid_item_container,
.product_grid_item:active .product_grid_item_container {
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  background: #fefefe;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
}

.list_related .product_grid_item:hover .product_grid_item_container,
.list_related .product_grid_item:focus .product_grid_item_container,
.list_related .product_grid_item:active .product_grid_item_container {
  position: relative;
}

.jshop_list_product .list-product-caption {
  padding: 15px 15px 10px;
}

.jshop_list_product .hidden_block_information {
  display: none;
}

.jshop_list_product .list_related .hidden_block_information {
  display: block;
}

.hidden_block_information .description {
  margin-top: 15px;
}

.product_grid_item:hover .hidden_block_information,
.product_grid_item:focus .hidden_block_information {
  display: block;
}

.list-row:hover .hidden_block_information,
.list-row:focus .hidden_block_information,
.slim-row:hover .hidden_block_information,
.slim-row:focus .hidden_block_information {
  display: block;
}

.jshop_list_product .jshop .old_price {
  text-decoration: line-through;
  font-style: normal;
  padding-bottom: 1px;
  display: block;
}

.jshop_list_product .product .description {
  font-size: 11px;
  padding-bottom: 5px;
}

.jshop_list_product .product .name,
.jshop_list_product .product .buttons {
  padding-top: 2px;
}

.jshop_list_product .product .name {
  flex: 1 1 100%;
  flex-wrap: wrap;
}

.jshop_list_product .product div.count_commentar {
  text-align: center !important;
}

.jshop_list_product .jshop_related {
  padding-bottom: 5px;
}

.jshop_list_product .image_block {
  padding: 2px;
}

.jshop_list_product .jshop .not_available {
  margin-top: 4px;
}

.jshop_list_product .box_products_sorting img {
  cursor: pointer;
}

.jshop_list_product .margin_filter {
  height: 8px;
}

.jshop_list_product .box_manufacrurer,
.jshop_list_product .box_category,
.jshop_list_product .filter_price {
  white-space: nowrap;
}

.jshop_list_product .clear_filter {
  font-size: 10px;
}

.rp-brooksite .jshop_list_product [class*="productitem_"] {
  background-color: #fff;
  border: 1px solid #e8e8e8;
  margin-bottom: 20px;
}

.rp-brooksite .jshop_list_product .image .jshop_img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.rp-brooksite .jshop_list_product .review_mark {
  margin: 0 auto;
}

.rp-brooksite .jshop_list_product .list-product-caption {
  text-align: center;
}

.rp-brooksite .jshop_list_product .name h4 {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.1;
}

.rp-brooksite .jshop_list_product .name h4 a {
  text-transform: uppercase;
}

.rp-brooksite .jshop_list_product .name h4 a:hover {
  color: #252a2f;
}

.rp-brooksite .jshop_list_product .button_detail.more_link {
  font-size: 10px;
  text-transform: none;
}

.jshop.rp-brooksite h1,
h1.jshop-product-name {
  margin: 0;
  line-height: 1.1;
}

.rp-brooksite .cat_short h2 {
  font-size: 22px;
  text-transform: uppercase;
  line-height: 1.1;
}

.rp-brooksite #list_product_image_middle .main_image {
  border: 1px solid #ddd;
}

.rp-brooksite .jshop_list_product .list-product-caption,
.rp-brooksite .jshop_list_product .description,
.rp-brooksite .productfull .old_price,
.rp-brooksite .productfull .manufacturer_name span,
.rp-brooksite .productfull .vendorinfo span,
.rp-brooksite .productfull .vendorinfo a,
.rp-brooksite .productfull .deliverytime span,
.rp-brooksite .productfull .productweight span,
.rp-brooksite .productfull .jshop_prod_attributes span,
.rp-brooksite .productfull .jshop_prod_attributes select,
.rp-brooksite .productfull .product-full-caption {
  color: #8b8b8b;
}

.rp-brooksite .jshop_list_product .price_block {
  padding: 10px 0;
}

.rp-brooksite .jshop_list_product .jshop_price {
  font-size: 24px;
  padding-right: 7px;
}

.rp-brooksite .jshop_list_product .old_price_wrap {
  min-height: 26px;
}

.rp-brooksite .jshop_list_product .old_price {
  font-size: 18px;
  color: gray;
}

.rp-brooksite .jshop_list_product .jshop_price i,
.rp-brooksite .jshop_list_product .old_price i,
.rp-brooksite .productfull .prod_price i,
.rp-brooksite .productfull .old_price i {
  font-size: 85%;
}

.productfull .jshop .image_middle .product_label {
  left: 16px;
}

.productfull .jshop .image_middle .modopprod_item .product_label {
  left: 1px;
}

.jshop .product_label {
  position: absolute;
  margin-left: 0;
  margin-top: 0;
  left: 1px;
  top: 1px;
}

.product_label.multilabel img {
  display: block;
}

.toList .product_label {
  max-width: 50px;
}

.jshop .product_label .label_name {
  font-weight: 700;
  color: #950000;
}

.rp-brooksite .productfull .prod_price,
.rp-brooksite .productfull .old_price {
  display: inline-block;
}

.productfull .prod_price_text {
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 700;
}

.productfull #block_price {
  font-size: 24px;
  font-weight: 700;
}

.jshop_list_product .product .taxinfo,
.jshop_list_product .product .plusshippinginfo,
.jshop_list_product .product .plusshippinginfo a,
.productfull .plusshippinginfo,
.productfull .plusshippinginfo a,
.productfull .taxinfo,
.jshop_subtotal .plusshippinginfo,
.jshop_subtotal .plusshippinginfo a,
.jshop_subtotal .free_discount {
  font-size: 11px;
}

.rp-brooksite .productfull .list_product_demo table {
  background-color: #fff;
  border: 1px solid #ddd;
  margin: 7px 7px 0 0;
}

.rp-brooksite .productfull .product-full-caption {
  padding: 7px 0;
}

.rp-brooksite .productfull .manufacturer_name,
.rp-brooksite .productfull .vendorinfo,
.rp-brooksite .productfull .deliverytime {
  padding-bottom: 7px;
}

.rp-brooksite .productfull .old_price {
  font-size: 18px;
  margin-left: 7px;
}

.rp-brooksite .productfull .prod_buttons .buttons {
  padding-left: 0;
}

.rp-brooksite .productfull .nav-tabs {
  margin-bottom: 0;
}

.rp-brooksite .productfull .tab-content {
  background-color: #fff;
  border: 1px solid #ddd;
  border-top: 0;
  padding: 15px;
}

.rp-brooksite .jshop_related .list-product-caption {
  text-align: center;
}

.rp-brooksite .productfull .prod_buttons .buttons {
  font-size: 0;
  letter-spacing: -1px;
  line-height: 0;
}

.rp-brooksite .productfull .prod_buttons .buttons .input-append {
  margin-right: 3px;
  font-size: inherit;
  letter-spacing: normal;
  line-height: normal;
}

.productfull .prod_buttons {
  margin-top: 15px;
}

.productfull div.prod_url {
  text-align: right !important;
}

.jshop_list_manufacturer .manufacturer .image {
  padding-right: 5px;
}

.shipping_weight_to_price {
  margin-left: 24px;
  margin-top: 5px;
  border: 1px solid #000;
}

.shipping_weight_to_price .price,
span.prod_attr_img {
  padding-left: 5px;
}

span.prod_attr_img img,
.radio_attr_label img {
  width: 18px;
  height: 18px;
}

div#jshop_review_write {
  background: url(../image/extra_bg.svg) #f5f5f5;
  padding: 15px;
}

.review_header {
  margin-top: 20px;
  font-weight: 700;
  border-bottom: 1px solid #999;
}

.review_item {
  border-bottom: 1px dotted #999;
  padding: 10px 10px 10px 0;
}

.review_user {
  font-weight: 700;
}

.review_text {
  padding-top: 2px;
  padding-bottom: 2px;
}

.review_mark {
  color: #333;
  height: 18px;
}

#jshop_review_write .inputbox {
  width: 180px;
}

.stars_no_active {
  height: 16px;
  background-image: url(../images/star.gif);
  background-repeat: repeat-x;
  background-position: 0 0;
}

.stars_active {
  height: 16px;
  background-image: url(../images/star.gif);
  background-repeat: repeat-x;
  background-position: 0 -16px;
}

input.btn.list-btn.button.validate {
  margin-top: 30px;
}

.jshop .downlod_order_invoice {
  float: right;
}

.jshop .order_payment_params {
  padding-top: 3px;
  font-size: 11px;
}

.vendordetailinfo .name {
  width: 130px;
}

.requiredtext {
  padding-bottom: 10px;
  color: #a00;
  font-size: 11px;
}

.jshop .input_type_radio {
  margin-right: 10px;
  white-space: nowrap;
  display: inline-block;
}

.jshop_profile_data {
  margin-bottom: 10px;
}

.jshop_profile_data .name,
.jshop_profile_data span {
  font-weight: 700;
}

.jshop_profile_data span.subinfo,
.jshop_profile_data .jshop_user_group_info {
  font-size: 10px;
}

.jshop .groups_list .title {
  width: 150px;
}

.jshop_menu_level_0,
.jshop_menu_level_0_a {
  margin-top: 3px;
  margin-bottom: 3px;
}

.jshop_menu_level_1,
.jshop_menu_level_1_a {
  padding-left: 10px;
  margin-top: 3px;
  margin-bottom: 3px;
}

.jshop_menu_level_2,
.jshop_menu_level_2_a {
  padding-left: 20px;
  margin-top: 3px;
  margin-bottom: 3px;
}

.jshop_menu_level_0_a a,
.jshop_menu_level_1_a a,
.jshop_menu_level_2_a a {
  font-weight: 700 !important;
}

.label_products .block_item,
.top_rating .block_item,
.bestseller_products .block_item,
.latest_products .block_item,
.random_products .block_item {
  margin-bottom: 10px;
}

.jshop.filters {
  font-size: 12px;
}

.jshop_filters .filter_price {
  padding-right: 5px;
}

.jshop_filters .clear_filter {
  display: block;
  font-size: 10px;
}

.hit-rating {
  color: #999;
  margin-left: 5px;
  font-size: 12px;
}

.border-bottom-dashed {
  border-bottom: 1px dashed #ccc;
}

.jshop_button_print {
  width: 30px;
  float: right;
  text-align: right;
}

.extra_fields .extra_fields_group {
  font-weight: 700;
  padding-top: 3px;
}

.filter_characteristic .characteristic_name {
  padding-top: 5px;
}

.filter_characteristic .characteristic_group {
  font-weight: 700;
  padding-top: 5px;
}

.jshop td.attributes_title {
  width: 100px;
}

.jshop span.attributes_description,
.jshop span.freeattribut_description {
  font-style: italic;
  display: block;
}

.jshop span.extra_fields_description {
  font-style: italic;
}

table.jshop td,
.jshop_list_product .product td,
.jshop_list_manufacturer .manufacturer td,
.jshop .prod_attr_img {
  vertical-align: top;
}

.jshop .cart .product_name {
  text-align: left;
}

.jshop .prod_qty_input input {
  width: 20px;
}

.productfull .image_middle {
  position: relative;
}

.productfull .manufacturer_name,
.productfull .prod_free_attribs {
  padding-bottom: 10px;
}

.productfull .price_prod_qty_list {
  margin-bottom: 10px;
}

.productfull .button_back {
  margin-top: 30px;
}

.jshop_img_description #list_product_image_thumb {
  display: block;
}

#list_product_image_thumb .owl-carousel .owl-item {
  position: relative;
}

#list_product_image_thumb .owl-carousel .owl-item .video-thumb:before {
  position: absolute;
  content: "\f167";
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  color: #f44336;
  font-family: "Font Awesome 5 Brands";
  z-index: 1;
  opacity: 0.75;
}

#list_product_image_middle a.popup-youtube {
  position: relative;
}

#list_product_image_middle a.popup-youtube:after {
  position: absolute;
  content: "\f167";
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 5rem;
  color: #f44336;
  font-family: "Font Awesome 5 Brands";
  z-index: 1;
  opacity: 0.75;
}

.productfull .text_zoom {
  padding-bottom: 5px;
  padding-top: 3px;
}

.productfull .text_zoom img {
  float: left;
  padding-right: 5px;
}

.productfull a.lightbox:hover {
  color: #000 !important;
}

.fielderror {
  background-color: #fdc055 !important;
}

.jshop_list_category {
  margin-top: 20px;
}

.jshop_list_category .row {
  display: flex;
  flex-wrap: wrap;
}

.jshop_list_category .row .span3 {
  align-self: stretch;
  margin-bottom: 1rem;
}

.jshop_categ {
  height: 100%;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  text-align: center;
}

.jshop_categ.category:hover {
  transform: scale(1.05);
  transition: all 0.5s;
  -webkit-box-shadow: 0 0 15px #ccc;
  -moz-box-shadow: 0 0 15px #ccc;
  -o-box-shadow: 0 0 15px #ccc;
  box-shadow: 0 0 15px #ccc;
}

.jshop_prod_cart {
  margin-bottom: 20px;
}

.row-filter-price {
  margin-top: 25px;
}

input.list-btn.start {
  line-height: inherit;
  height: inherit;
  padding: 4px 6px;
  margin-bottom: 0;
}

.cart-result-wrap {
  margin-top: 15px;
}

.cart-table-wrap {
  border: 1px solid #dcdcdc;
}

.cart.rp-brooksite th.jshop_img_description_center {
  width: 20%;
}

.cart.rp-brooksite th.product_name {
  width: 25%;
}

.cart.rp-brooksite th.single_price {
  width: 15%;
}

.cart.rp-brooksite th.quantity {
  width: 20%;
}

.cart.rp-brooksite th.total_price {
  width: 15%;
}

.cart.rp-brooksite th.remove {
  width: 5%;
}

.cart-checkout.rp-brooksite .jshop_img_description_center img {
  max-height: 70px;
}

span.cart_reload {
  cursor: pointer;
}

span.cart_reload img {
  cursor: pointer;
  width: 20px;
  height: 20px;
  vertical-align: top;
  margin-top: 5px;
}

.remove a.button-img img {
  vertical-align: top;
  margin-top: 5px;
}

.jshop.cart-checkout.rp-brooksite {
  font-size: 14px;
}

.jshop.cart-checkout.rp-brooksite .cart-result-wrap {
  border: 1px solid #dcdcdc;
  background: #f5f5f5;
  padding: 5px;
  margin-top: 0;
  text-align: right;
}

.jshop.address_block.rp-brooksite,
.jshop.shipping_block.rp-brooksite,
.jshop.payment_block.rp-brooksite,
.jshop.previewfinish_block.rp-brooksite {
  background: url(../image/extra_bg.svg) #f5f5f5;
  padding: 15px;
  margin-top: 30px;
}

.jshop.cart-checkout.rp-brooksite .list_attribute,
.jshop.cart-checkout.rp-brooksite .list_extra_field {
  display: none;
}

input#finish_registration:hover {
  transform: scale(1.005);
  transition: all 0.2s;
}

.mb5 {
  margin-bottom: 5px;
}

.display-inline {
  display: inline-block;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.rp-brooksite .input-prepend input,
.input-prepend select {
  width: 100%;
  max-width: 150px;
}

.jshop.rp-brooksite input,
.jshop.rp-brooksite select {
  border-radius: 0;
}

.rp-brooksite .input-prepend input {
  height: inherit;
}

.bs3 .rp-brooksite .input-prepend select {
  height: inherit;
  line-height: inherit;
}

.rp-brooksite.bs3 .input-prepend select {
  vertical-align: middle;
}

.rp-brooksite .input-append .add-on,
.rp-brooksite .input-prepend .add-on {
  height: inherit;
  text-shadow: none;
  border-radius: 0;
  margin-right: 0;
}

.rp-brooksite .box_price_from input#price_from,
.rp-brooksite .box_price_to input#price_to {
  width: inherit;
  max-width: 80px;
}

.rp-brooksite .margin-bot {
  margin-bottom: 15px;
  padding: 20px 0;
}

.rp-brooksite .select-view {
  border: solid #d0d0d0;
  border-width: 1px 0;
  margin: 15px 0;
  padding: 17px 0 8px;
}

.rp-brooksite .to-grid,
.rp-brooksite .to-list,
.rp-brooksite .to-slim {
  font-size: 25px;
  line-height: 35px;
  cursor: pointer;
}

.rp-brooksite .to-grid {
  padding-right: 7px;
}

.rp-brooksite .to-list {
  padding-right: 7px;
}

.rp-brooksite .to-slim {
  padding-right: 7px;
}

.rp-brooksite .attrib {
  overflow: auto;
  clear: both;
}

.rp-brooksite .hide_pop {
  position: absolute;
  top: 7px;
  right: 7px;
  display: block;
  font-size: 16px;
  color: red;
  cursor: pointer;
  z-index: 99999;
}

.rp-brooksite .atcart.btn.list-btn,
.rp-brooksite .atwl.btn.list-btn {
  margin-left: -2px;
  padding: 6px 4px;
}

.rp-brooksite .list-btn {
  border: 0 solid transparent;
  font-size: 14px;
  text-shadow: none;
  box-shadow: none;
  margin-right: 2px;
  margin-bottom: 5px;
  outline: 0;
  padding: 6px 10px;
  vertical-align: middle;
}

.rp-brooksite button.btn.list-btn.wl {
  margin-left: 2px;
}

.rp-brooksite .list-block .list-btn {
  padding: 6px;
}

.rp-brooksite .jshop_list_product .product-button-wishlist.btn.list-btn,
.rp-brooksite .jshop_list_product .atwl.btn.list-btn,
.rp-brooksite .jshop_list_product .btn.list-btn.compare_link_to_list,
.rp-brooksite .jshop_list_product .btn.list-btn.go_to_compre_list,
.rp-brooksite .jshop_list_product .btn.list-btn.remove_compare_list,
.rp-brooksite .jshop_list_product .inline_askquestion.btn.list-btn,
.rp-brooksite .jshop_list_product .inline_pqv.btn.list-btn {
  padding: 2px 4px;
}

.rp-brooksite .list-btn:hover,
.rp-brooksite .list-btn:focus {
  color: inherit;
  outline: 0;
}

.rp-brooksite .btn.list-btn.remove_compare_list {
  margin: 0 0 5px -3px;
  padding: 6px 4px;
}

.rp-brooksite.anchor-absolute .jshop_list_product .btn.list-btn.remove_compare_list {
  padding: 2px 8px;
}

.rp-brooksite .list-btn.button_buy,
.rp-brooksite .list-btn.buy {
  text-transform: uppercase;
  padding: 0.375rem 0.75rem;
}

.rp-brooksite .list-btn.button_buy:hover,
.rp-brooksite .list-btn.buy:hover {
  color: inherit;
}

.rp-brooksite .input-append,
.rp-brooksite .input-prepend {
  margin-bottom: 0;
  display: inline-block;
  white-space: nowrap;
}

.rp-brooksite .input-append.count_block .inputbox {
  font-size: 14px;
  border: 0 solid transparent;
  border-radius: 0;
  box-shadow: none;
  min-width: 20px;
  max-width: 40px;
  text-align: center;
}

.rp-brooksite button.btn.list-btn.count {
  border: 0 solid transparent;
  padding: 6px;
}

.rp-brooksite input.list-btn {
  font-size: 14px;
  height: auto;
}

.rp-brooksite .list-btn.button.remove_comp {
  margin-left: -3px;
  border-radius: 0;
  padding: 6px;
}

.rp-brooksite .cart_quantity,
.cart_unit_price {
  margin: 10px 0;
}

.cart_unit_price span {
  white-space: nowrap;
}

.rp-brooksite .cart_total_block {
  font-weight: 700;
  font-size: 14px;
  padding: 10px 0;
}

.rp-brooksite #checkout {
  margin-top: 10px;
}

.rp-brooksite hr {
  border-top: 1px solid #a7a7a7;
  margin-top: 15px;
  margin-bottom: 15px;
}

.rp-brooksite .btn:focus {
  outline: 0;
}

.rp-brooksite .btn.list-btn,
rpb_thems button.btn.list-btn,
.rp-brooksite input.btn.list-btn,
.rp-brooksite .icons-module input,
.rp-brooksite .icons-module textarea,
.rp-brooksite .icons-module .uneditable-input {
  border-radius: 0;
}

.rp-brooksite .jshop_list_product [class*="productitem_"].list-row {
  margin-bottom: 0;
  padding: 7px;
}

.rp-brooksite .list-row::after {
  display: table;
  content: "";
  clear: both;
}

.rp-brooksite .list-row .name h4 {
  display: inline;
}

.rp-brooksite .list-row .old_price {
  display: block;
}

.rp-brooksite .jshop_list_product .slim-row .price_block {
  min-height: inherit;
  padding: 0;
}

.rp-brooksite .list-block.block_product {
  padding-bottom: 0;
}

.rp-brooksite .jshop_prod_cart {
  background-color: #fff;
  margin-top: 15px;
  padding: 7px;
}

.jshop_prod_cart .quantity .data {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rp-brooksite .jshop_prod_cart input,
.rp-brooksite .jshop_prod_cart button.btn.list-btn.count,
.rp-brooksite .jshop_prod_cart a.list-btn {
  padding: 4px;
}

input[type="text"][id*="quantity"] {
  display: inline-block;
}

.clear {
  clear: both;
}

.dblock {
  display: block;
}

.dnone {
  display: none;
}

.dnone-force {
  display: none !important;
}

.round-loupe {
  border-radius: 75px;
  border: 5px solid #ccc;
}

.magnifier {
  background-color: #fff;
}

.txtdnnone {
  display: none !important;
}

.jshop.productfull .owl-prev {
  position: absolute;
  background-color: #666;
  color: #fff;
  top: 50%;
  left: 0;
  margin-top: -10px;
  opacity: 0.5;
  text-align: center;
}

.jshop.productfull .owl-next {
  position: absolute;
  background-color: #666;
  color: #fff;
  top: 50%;
  right: 0;
  margin-top: -10px;
  opacity: 0.5;
  text-align: center;
}

.jshop.productfull .owl-prev:hover,
.jshop.productfull .owl-next:hover {
  opacity: 1;
}

.rel-prod.fade-to {
  opacity: 0;
}

.rp-brooksite .jshop_list_product .clear_filter {
  display: inline-block;
  vertical-align: middle;
}

.img-brooksus-rouble {
  background: url(../img/rub2.png) 0 0 transparent no-repeat;
  background-size: contain;
  display: inline-block;
  min-height: 10px;
  min-width: 10px;
}

.rp-brooksite .jshop input[name="finish_registration"] {
  width: auto;
  max-width: inherit;
}

.attrib .attributes_title,
.attrib .attributes_value {
  display: inline-block;
  vertical-align: top;
}

.att_none {
  margin: 5px 0;
}

.att_none span.radio_attr_label {
  font-size: 14px;
}

.attributes_title span[title*=" "] {
  cursor: help;
  border-bottom: 1px dotted #ccc;
}

.clear {
  clear: both;
}

.attrib-empty-option {
  position: absolute;
  z-index: 9;
  max-width: 280px;
  display: none;
  opacity: 0;
}

.attrib-empty-option div {
  position: relative;
  width: 100%;
  min-height: 25px;
  background: #fff;
  padding: 15px 10px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #87203f;
  box-shadow: 0 0 7px #ccc;
  max-width: 250px;
}

.attrib-empty-option span {
  position: absolute;
  top: -5px;
  right: 5px;
  font-size: 18px;
  cursor: pointer;
  color: #999;
}

.attrib-empty-option div::before,
.attrib-empty-option div::after {
  content: "";
  position: absolute;
  left: 47%;
  bottom: -20px;
  border: 10px solid transparent;
  border-top: 10px solid #ccc;
}

.attrib-empty-option div::after {
  border-top: 10px solid #fff;
  bottom: -19px;
}

.disable_buttonbuy {
  opacity: 0.5;
}

a.prev.btn.list-btn,
a.next.btn.list-btn,
a.prev.btn.list-btn i,
a.next.btn.list-btn i {
  box-shadow: none;
  transform: scale(0);
  transform: translateX(0);
  transition: all 0.5s ease-in-out;
}

a.prev.btn.list-btn:hover,
a.next.btn.list-btn:hover {
  box-shadow: 0 0 5px #ccc;
  transition: all 0.5s ease-in-out;
}

a.prev.btn.list-btn:hover i,
a.next.btn.list-btn:hover i {
  transform: scale(1.15);
  transform: translateX(2px);
  transition: all 0.5s ease-in-out;
}

a.prev.btn.list-btn:hover i {
  transform: translateX(-2px);
}

table.w100 {
  width: 100%;
}

table.w95 {
  width: 95%;
}

tr.w100 {
  width: 100%;
}

td.w50 {
  width: 50%;
}

td.dotted-bg {
  border-bottom: 1px dotted #ccc;
}

.pt-5 {
  padding-top: 5px;
}

.pr-5 {
  padding-right: 5px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pl-5 {
  padding-left: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pr-10 {
  padding-right: 10x;
}

.pb-10 {
  padding-bottom: 10px;
}

.pl-10 {
  padding-left: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pr-15 {
  padding-right: 15px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pl-15 {
  padding-left: 15px;
}

.mt-5 {
  margin-top: 5px;
}

.mr-5 {
  margin-right: 5px;
}

.mb-5 {
  margin-bottom: 5px;
}

.ml-5 {
  margin-left: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mr-10 {
  margin-right: 10x;
}

.mb-10 {
  margin-bottom: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mr-15 {
  margin-right: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.ml-15 {
  margin-left: 15px;
}

.bs2 div#askquestion_modal {
  max-width: 310px;
  left: 50%;
  margin-left: -155px;
}

.bs2 div#askquestion_modal .modal-body {
  width: inherit;
  max-height: inherit;
}

#askquestion_modal h4 {
  color: #444;
}

#askquestion #askquestionForm {
  color: #444;
  height: auto;
  z-index: 99;
  text-align: left;
  text-transform: none !important;
  font-size: 14px;
  width: 100%;
  max-width: 260px;
  margin: 0 auto;
}

#askquestion form#askquestionForm input,
#askquestion form#askquestionForm textarea {
  background: #fff none repeat scroll 0 0;
  outline-style: none;
  outline-width: medium;
  width: 100%;
  height: auto;
  max-width: 300px;
  border: 1px solid #bbb;
  margin: 0 0 10px 0;
  padding: 8px;
  color: #343434;
}

#askquestion form#askquestionForm .submit {
  background: #f5410f none repeat scroll 0 0;
  outline-style: none;
  outline-width: medium;
  width: 100%;
  max-width: 260px;
  padding: 5px;
  border: 3px solid #f52d0f;
  outline-color: -moz-use-text-color;
  font-size: 1em;
  cursor: pointer;
  color: #fff;
  text-transform: uppercase;
  font-weight: bolder;
  margin-top: 10px;
}

#askquestion form#askquestionForm .disclaimeraq {
  border: 1px solid #bbb;
  margin: 5px 0 15px 0;
  padding: 5px 0;
  display: inline-block;
  width: 100%;
  max-width: 260px;
}

#askquestion #askquestionForm .red {
  color: #f5410f;
}

#askquestion form#askquestionForm label.error {
  font-style: italic;
  font-size: 10px;
  margin: 0;
}

#askquestion form#askquestionForm input.contact.error {
  background-color: #edbe9c;
}

#askquestion form#askquestionForm label {
  margin: 0 0 0 20px;
  display: inline-block;
  font-size: 14px;
}

#askquestion form#askquestionForm #loading {
  background: url(../image/ajax-loader.gif) no-repeat;
  width: 55px;
  height: 55px;
  margin: 100px auto;
  display: none;
  z-index: 999;
}

#askquestion #callback {
  font-size: 1.1em;
  text-align: center;
  color: #444;
  width: 260px;
  margin: 20px auto;
  display: none;
}

#askquestion .holder {
  margin: 0 auto;
  padding-top: 10px;
  width: 100%;
  max-width: 260px;
}

.disclaimeraq img {
  float: left;
  margin: 0 5px 5px 0;
  max-width: 75px;
  max-height: 75px;
}

.disclaimeraq .product_name_ask {
  color: #f5410f;
}

.inline_askquestion_prod.free {
  font-size: 14px;
  background-color: transparent;
}

.inline_askquestion img,
.inline_askquestion_prod img {
  border: none !important;
}

.dnone {
  display: none;
}

.effect-1 .animate {
  -webkit-animation: fadeIn 0.65s ease forwards;
  animation: fadeIn 0.65s ease forwards;
}

a.lightbox {
  outline: 0;
  display: block;
}

.rp-brooksite .jshop input[type="button"],
.rp-brooksite .jshop input[type="submit"],
.rp-brooksite .jshop select {
  max-width: 150px;
}

.rp-brooksite .jshop #order {
  max-width: 115px;
}

.rp-brooksite .jshop .jshop_prod_attributes select {
  display: inline-block;
}

.bs3 .row-fluid {
  margin-left: -15px;
  margin-right: -15px;
}

.bs3 .row-fluid:before,
.bs3 .row-fluid:after {
  content: " ";
  display: table;
}

.bs3 .row-fluid:after {
  clear: both;
}

.bs3 .rp-brooksite .form-control {
  width: auto;
}

.rp-brooksite .popover-content {
  color: #444;
}

.list-row a.btn.list-btn.go_to_compre_list {
  position: relative;
  top: 0;
  right: inherit;
}

.list-row a.btn.list-btn.remove_compare_list {
  position: relative;
  top: 0;
  right: inherit;
}

.zoomContainer {
  z-index: 3;
}

.owl-carousel .animated {
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.owl-height {
  -webkit-transition: height 500ms ease-in-out;
  -moz-transition: height 500ms ease-in-out;
  -ms-transition: height 500ms ease-in-out;
  -o-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out;
}

.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-controls .owl-dot,
.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-nav .owl-prev {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel .owl-refresh .owl-item {
  display: none;
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  -webkit-transform-style: preserve-3d;
}

.owl-item img.jshop_img_thumb {
  margin-left: 0;
}

.owl-carousel.owl-text-select-on .owl-item {
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}

.owl-carousel .owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

.no-js .owl-carousel {
  display: block;
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-item img {
  transform-style: preserve-3d;
}

.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url(img/owl.video.play.png) no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -webkit-transition: scale 100ms ease;
  -moz-transition: scale 100ms ease;
  -ms-transition: scale 100ms ease;
  -o-transition: scale 100ms ease;
  transition: scale 100ms ease;
}

.owl-carousel .owl-video-play-icon:hover {
  -webkit-transition: scale(1.3, 1.3);
  -moz-transition: scale(1.3, 1.3);
  -ms-transition: scale(1.3, 1.3);
  -o-transition: scale(1.3, 1.3);
  transition: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-play-icon,
.owl-carousel .owl-video-playing .owl-video-tn {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
}

.owl-theme .owl-controls {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-controls .owl-nav [class*="owl-"] {
  color: #fff;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #d6d6d6;
  display: inline-block;
  cursor: pointer;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.owl-theme .owl-controls .owl-nav [class*="owl-"]:hover {
  background: #869791;
  color: #fff;
  text-decoration: none;
}

.owl-theme .owl-controls .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline;
}

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #d6d6d6;
  display: block;
  -webkit-backface-visibility: visible;
  -webkit-transition: opacity 200ms ease;
  -moz-transition: opacity 200ms ease;
  -ms-transition: opacity 200ms ease;
  -o-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #869791;
}

.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1051;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1052;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 8px;
}

.mfp-container:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  z-index: 1054;
  margin: 0 auto;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #ccc;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1053;
}

.mfp-preloader a {
  color: #ccc;
}

.mfp-preloader a:hover {
  color: #fff;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: 0;
  z-index: 1046;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  filter: alpha(opacity=65);
  color: #fff;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
  padding: 0 0 18px 10px;
}

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1;
  filter: alpha(opacity=100);
}

.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #fff;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #ccc;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  filter: alpha(opacity=65);
  top: 50%;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  margin: -55px 0 0;
  padding: 0;
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1;
  filter: alpha(opacity=100);
}

.mfp-arrow:before,
.mfp-arrow:after,
.mfp-arrow .mfp-b,
.mfp-arrow .mfp-a {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

.mfp-arrow:after,
.mfp-arrow .mfp-a {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before,
.mfp-arrow .mfp-b {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after,
.mfp-arrow-left .mfp-a {
  border-right: 17px solid #fff;
  margin-left: 31px;
}

.mfp-arrow-left:before,
.mfp-arrow-left .mfp-b {
  margin-left: 25px;
  border-right: 27px solid #3f3f3f;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after,
.mfp-arrow-right .mfp-a {
  border-left: 17px solid #fff;
  margin-left: 39px;
}

.mfp-arrow-right:before,
.mfp-arrow-right .mfp-b {
  border-left: 27px solid #3f3f3f;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 40px 0;
}

.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: "";
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}

.mfp-figure small {
  color: #bdbdbd;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #f3f3f3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

.mfp-ie7 .mfp-img {
  padding: 0;
}

.mfp-ie7 .mfp-bottom-bar {
  width: 600px;
  left: 50%;
  margin-left: -300px;
  margin-top: 5px;
  padding-bottom: 5px;
}

.mfp-ie7 .mfp-container {
  padding: 0;
}

.mfp-ie7 .mfp-content {
  padding-top: 44px;
}

.mfp-ie7 .mfp-close {
  top: 0;
  right: 0;
  padding-top: 0;
}

#pqv_modal .mfp-container {
  height: auto;
  top: 100px;
}

div#pqv_modal .nav-tabs,
div#pqv_modal .mod-in-prod,
div#pqv_modal .inline_askquestion_prod,
div#pqv_modal .panel.panel-default.accordion-group:nth-child(2),
div#pqv_modal .panel.panel-default.accordion-group:nth-child(3),
div#pqv_modal .tab-content #related,
div#pqv_modal .tab-content #review,
div#pqv_modal .collapse_related,
div#pqv_modal .collapse_review,
div#pqv_modal .notabs_related,
div#pqv_modal .notabs_review {
  display: none;
}

div#pqv_modal .productfull.rp-brooksite .tab-content {
  border: 0;
}

.jshop_list_product a.product-button-wishlist.btn.list-btn,
.jshop_list_product a.atwl.btn.list-btn,
.jshop_list_product a.inline_askquestion.btn.list-btn,
.jshop_list_product a.btn.list-btn.compare_link_to_list,
.jshop_list_product a.btn.list-btn.go_to_compre_list,
.jshop_list_product a.btn.list-btn.remove_compare_list,
.jshop_list_product a.inline_pqv.btn.list-btn {
  background: #fefefe;
  color: #777;
  border: 1px solid #ccc;
  -webkit-transition: all 0.6s ease-in-out;
  -o-transition: all 0.75s ease-in-out;
  -moz-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
}

.rp-brooksite .jshop_list_product a.btn.list-btn.go_to_compre_list {
  color: #fff;
}

.rp-brooksite .jshop_list_product .btn.list-btn.remove_compare_list {
  padding: 2px 8px;
  margin-left: 1px;
}

.jshop_list_product a.product-button-wishlist.btn.list-btn:hover,
.jshop_list_product a.atwl.btn.list-btn:hover,
.jshop_list_product a.inline_askquestion.btn.list-btn:hover,
.jshop_list_product a.btn.list-btn.compare_link_to_list:hover,
.jshop_list_product a.btn.list-btn.go_to_compre_list:hover,
.jshop_list_product a.inline_pqv.btn.list-btn:hover,
.jshop_list_product a.btn.list-btn.remove_compare_list:hover {
  background: #777;
  color: #fff;
  border: 1px solid #ccc;
  -webkit-transition: all 0.75s ease-in-out;
  -o-transition: all 0.75s ease-in-out;
  -moz-transition: all 0.6s ease-in-out;
  transition: all 0.75s ease-in-out;
}

.jshop.rp-brooksite .tooltip.top .tooltip-inner {
  background-color: #777;
}

.jshop.rp-brooksite .tooltip.top .tooltip-arrow {
  border-top-color: #777;
}

.rp-brooksite .productfull .nav-tabs::after,
.rp-brooksite .productfull .nav-tabs::before {
  content: " ";
  display: table;
}

span.params-jshopkit {
  display: none;
}

.navigation_div {
  margin-top: 15px;
  margin-bottom: 30px;
}

.bs3 .inputbox {
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.bs2 .modal-body {
  overflow-y: auto;
}

.bs2 select,
.bs2 textarea,
.bs2 input[type="text"],
.bs2 input[type="password"],
.bs2 input[type="datetime"],
.bs2 input[type="datetime-local"],
.bs2 input[type="date"],
.bs2 input[type="month"],
.bs2 input[type="time"],
.bs2 input[type="week"],
.bs2 input[type="number"],
.bs2 input[type="email"],
.bs2 input[type="url"],
.bs2 input[type="search"],
.bs2 input[type="tel"],
.bs2 input[type="color"],
.bs2 .uneditable-input {
  height: auto;
}

.bs2 .form-horizontal .control-label {
  width: auto;
  min-width: 160px;
  padding-right: 5px;
  text-align: left;
}

.bs2 .form-horizontal .controls {
  margin-left: 0;
}

.rpBrooksAccordion .panel-title a {
  display: block;
}

@font-face {
  font-family: "IcoMoon";
  src: url("/../../../media/juifonts/IcoMoon.eot");
  src: url("/../../../media/juifonts/IcoMoon.eot?#iefix") format("embedded-opentype"), url("/../../../media/juifonts/IcoMoon.woff") format("woff"), url("/../../../media/juifonts/IcoMoon.ttf") format("truetype"), url("/../../../media/juifonts/IcoMoon.svg#IcoMoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: "IcoMoon";
  content: attr(data-icon);
  speak: none;
}

@-webkit-keyframes fadeIn {
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  100% {
    opacity: 1;
  }
}

.jshop_list_product a.product-button-wishlist.btn.list-btn,
.jshop_list_product a.atwl.btn.list-btn,
.jshop_list_product a.inline_askquestion.btn.list-btn,
.jshop_list_product a.btn.list-btn.compare_link_to_list,
.jshop_list_product a.btn.list-btn.go_to_compre_list,
.jshop_list_product a.btn.list-btn.remove_compare_list,
.jshop_list_product a.inline_pqv.btn.list-btn {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.product_grid_item_container .image {
  padding: 15px;
}

.hidden {
  display: none;
}

input.quantity {
  max-width: 50px;
}

.input-append {
  display: inline-block;
  margin-bottom: 0;
}

.inline_askquestion_prod {
  background-color: ButtonFace;
  color: ButtonText;
  margin-left: 3px;
  margin-right: 3px;
}

.fa {
  min-width: 16px;
  min-height: 16px;
}

.input-append,
.input-prepend {
  margin-bottom: 0;
}

.product_grid_item_container .ext-buttons,
.product_grid_item_container .button_detail {
  display: none !important;
}

.product_grid_item_container .buttons i {
  font-size: 20px;
}

.product_grid_item_container .list-product-caption {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.product_grid_item_container .buttons {
  order: -1;
  width: 100%;
}

.product_grid_item_container .name a {
  font-size: 12px;
  color: #444;
  font-weight: bold;
  text-transform: none !important;
  white-space: normal !important;
  text-align: left;
  line-height: 190%;
}

.product_grid_item_container {
  padding: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease-in-out;
}

.product_grid_item {
  border: none !important;
}

.product_grid_item_container .name {
  width: 60%;
  min-height: 3em;
  overflow: hidden;
}

.product_grid_item_container .price_block .jshop_price {
  color: #d94444;
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  display: block;
}

.product_grid_item_container .list-product-caption {
  padding: 0 0 10px;
}

.product_grid_item_container .price_block {
  padding-right: 10px !important;
}

.product_grid_item_container .buttons {
  text-align: right;
  position: relative;
}

.product_grid_item_container .buttons:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  top: 23px;
  background: #ddd;
}

.product_grid_item:hover .hidden_block_information,
.product_grid_item:focus .hidden_block_information {
  display: none !important;
}

.jshop_list_product .hidden_block_information {
  display: none !important;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

a {
  color: #333;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

img {
  max-width: 100%;
}

body {
  font-family: "Roboto", sans-serif;
  background: #fff;
  margin: 0;
  font-size: 15px;
  line-height: 1.5;
  min-width: 320px;
  overflow-x: hidden;
  -webkit-transition: right 0.2s ease;
  transition: right 0.2s ease;
  outline: 0;
}

body.body_move {
  right: 100%;
  overflow: hidden;
  position: relative;
}

a,
a:hover,
a:focus {
  text-decoration: none !important;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.1;
}

h1 {
  font-size: 36px;
  margin-top: 20px !important;
  font-weight: normal;
}

h2 {
  font-size: 28px;
  font-weight: normal;
  padding: 10px;
}

h3 {
  font-size: 20px;
  font-weight: normal;
}

h4 {
  font-size: 16px;
  font-weight: normal;
}

h5 {
  font-size: 14px;
  font-weight: normal;
}

h6 {
  font-size: 12px;
  font-weight: normal;
}

p {
  margin: 0;
  text-align: justify;
}

.top_bar {
  border-bottom: 1px solid #bfbdbd;
}

.top_bar_width {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.basket-top-header a {
  font-weight: bold;
}

.moduletable_logo {
  max-width: 350px;
}

.moduletable_btn-top-menu-mobile,
.moduletable_btn-open-menu,
.moduletable_btn-open-phone-mobile,
.moduletable_btn-open-serch-mobile {
  display: none;
}

.moduletable_btn-open-phone-mobile {
  order: 1;
}

.container_header_width {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1300px;
  margin: 0 auto;
  padding: 20px 20px 10px 20px;
}

.moduletable_js_cart {
  order: 4;
}

.moduletable_phones {
  order: 3;
}

.menu_top_menu {
  display: flex;
  align-items: center;
}

.menu_top_menu a:hover {
  color: #333;
}

.menu_top_menu > li > a {
  display: block;
}

.cart_login {
  display: flex;
  align-items: center;
}

.fixed .moduletable_logo {
  display: block;
  max-width: 200px;
}

.fixed {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 22;
}

.fixed .container_header_width {
  padding: 15px 20px 5px 20px;
}

#header {
  background: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.slick-slide,
.draggable,
.slick-track {
  height: 100% !important;
}

.slick-slide {
  background-position: center;
  background-size: cover;
  position: relative;
  box-sizing: border-box;
}

.text-box {
  position: absolute;
  display: inline-block;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  text-align: center;
  width: 100%;
  max-width: 1200px;
}

.text {
  -webkit-transform: scale(0.98, 1) skew(-15deg, 0deg);
  border: 2px solid #4d91ef;
  background-color: rgba(0, 0, 0, 0.46);
  margin: 0 55px;
}

.text-box .text_1 {
  -webkit-transform: scale(0.98, 1) skew(15deg, 0deg);
  line-height: 150%;
  display: block;
  line-height: 100%;
  padding: 20px;
  color: #fbb03b;
  font-size: 32px;
  text-transform: uppercase;
  text-shadow: 0 -1px 0 #000, 0 -1px 0 #000, 0 1px 0 #000, 0 1px 0 #000, -1px 0 0 #000, 1px 0 0 #000, -1px 0 0 #000, 1px 0 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.text-box a:focus {
  outline: 0;
}

.text-box .text_2 {
  display: block;
  font-size: 23px;
  padding-top: 30px;
  padding-bottom: 10px;
  font-weight: 800;
  text-shadow: 0 2px 10px #000;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  -webkit-transform: scale(0.98, 1) skew(15deg, 0deg);
}

.buttom_box {
  position: absolute;
  bottom: 30%;
  left: 50%;
  transform: translateX(-50%);
}

.buttom_box a {
  padding: 10px 40px;
  background-color: #9a001a;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  border: 2px solid #6f1d1d;
}

.slick-prev,
.slick-next {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  background: 0;
  font-size: 0 !important;
  outline: 0;
  height: 40px;
}

.slick-next {
  right: 0;
}

.main_slider {
  position: relative;
}

.slick-slide:before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  display: block;
}

.slick-prev:before {
  content: "\f137";
  font-family: FontAwesome;
  font-size: 40px !important;
  color: rgba(158, 158, 158, 0.52);
  position: absolute;
  top: 0;
  line-height: 100%;
}

.slick-next:before {
  content: "\f138";
  font-family: FontAwesome;
  font-size: 40px !important;
  color: rgba(158, 158, 158, 0.52);
  position: absolute;
  top: 0;
  line-height: 100%;
}

.slick-dots {
  display: flex !important;
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
}

.slick-dots li {
  margin-right: 20px;
}

.slick-dots li:last-child {
  margin-right: 0;
}

.slick-dots button {
  border-radius: 50%;
  font-size: 0;
  width: 7px;
  height: 14px;
  background-color: rgba(255, 255, 255, 0.56);
  border: 1px solid rgba(70, 70, 70, 0.59);
}

.slick-active button {
  background-color: rgba(0, 0, 0, 0.6) !important;
}

.slick-prev,
.slick-next {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  background: 0;
  font-size: 0 !important;
  outline: 0;
  height: 40px;
}

.slick-next {
  right: 0;
}

.slick-prev:before {
  content: "\f053";
  font-family: FontAwesome;
  font-size: 40px !important;
  color: rgba(255, 255, 255, 0.78);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  line-height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  padding: 20px 3px;
}

.slick-next:before {
  content: "\f054";
  font-family: FontAwesome;
  font-size: 40px !important;
  color: rgba(255, 255, 255, 0.78);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  line-height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  padding: 20px 3px;
}

.mycart_wrapp.grey .mycart_mini_txt {
  background: 0;
}

.mycart_mini_txt:not(.externbootstrap) {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5251f !important;
  margin-left: -20px;
  margin-top: -20px;
  box-shadow: none;
  border: 2px solid #fff;
}

.mycart_mini_txt a {
  color: #fff !important;
  font-size: 16px;
  text-align: center;
  text-decoration: none !important;
  padding-top: 0;
  width: auto;
  height: auto;
  display: block;
  position: relative;
}

.mycart_wrapp {
  display: block;
  position: relative;
  min-width: auto;
  min-height: auto;
  max-width: auto;
  z-index: 999;
  line-height: 1;
  margin: 0;
}

.phone-adress-header,
.phone-adress-header-item {
  display: flex;
}

.phone-adress-header-item {
  align-items: flex-end;
}

.phone-adress-header-item i {
  font-size: 30px;
  margin-right: 10px;
  color: #f5251f;
  margin-bottom: 10px;
}

.adress-block-header {
  margin-left: 30px;
}

.adress-block-header .phone-adress-header-item-text-top-text {
  font-size: 12px;
  margin-bottom: 5px;
}

.phone-adress-header-item-text-top-text,
.phone-adress-header-item-text-bottom-text {
  display: block;
  font-size: 12px;
}

.phone-adress-header-item-text-top-text {
  font-size: 18px;
  font-weight: bold;
}

.moduletable_search {
  width: 100%;
  max-width: 300px;
}

.nav a {
  line-height: 120%;
}

.menu_login_menu {
  display: flex;
  align-items: center;
}

.menu_login_menu li a {
  position: relative;
  display: block;
  padding: 10px 15px;
  color: #f1b422;
}

.menu_login_menu .item-119,
.menu_login_menu .item-120 {
  position: relative;
  padding-left: 7px;
}

.tab_markup .nav-tabs {
  padding-left: 0;
}

.pagination ul {
  display: flex;
  flex-wrap: wrap;
}

.pagination ul li {
  list-style: none;
  padding: 0 !important;
}

.pagination ul li a {
  border: 2px solid #dadada;
  width: 37px;
  height: 37px;
  text-align: center;
  line-height: 33px;
  display: block;
  margin: 0 8px 0 0;
  background: #fff;
  font-size: 13px;
  color: #dadada;
  font-weight: bold;
}

.pagination ul li .pagenav {
  border: 2px solid #dadada;
  width: auto;
  min-width: 40px;
  height: 37px;
  text-align: center;
  white-space: nowrap;
  line-height: 23px;
  display: inline-block;
  margin: 0 8px 0 0;
  background: #fff;
  font-size: 13px;
  color: #b8b8b8;
  font-weight: bold;
  border-radius: 7px;
}

.pagination ul li .pagenav:hover {
  background: #4d91ef;
  border: 2px solid #4d91ef;
  color: #fff;
  background: #65cc5b;
  border: 2px solid #63ca58;
}

.pagination-start,
.pagination-end {
  display: none;
}

.pagination-next .pagenav,
.pagination-prev .pagenav {
  font-size: 0 !important;
  position: relative;
}

.pagination-next .pagenav:before {
  content: "\f054";
  font-family: FontAwesome;
  position: absolute;
  right: 10px;
  top: 9px;
  color: #dadada;
  z-index: 9;
  font-size: 16px;
  line-height: 100%;
}

.pagination-prev .pagenav {
  position: relative;
  font-size: 0 !important;
}

.pagination-prev .pagenav:before {
  content: "\f053";
  font-family: FontAwesome;
  position: absolute;
  right: 12px;
  top: 9px;
  color: #dadada;
  z-index: 9;
  font-size: 16px;
  line-height: 100%;
}

.owl-carousel .owl-item img {
  max-width: 200px;
  margin: 0 auto;
}

.jshop.productfull .owl-next,
.jshop.productfull .owl-prev {
  height: 40px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.owl-carousel .owl-item p {
  text-align: center !important;
}

.moduletable.brands {
  max-width: 1200px;
  margin: 0 auto;
}

.moduletable.brands #extcarousel {
  padding: 0;
}

.brands .h-mag {
  margin: 0 10px 45px 10px;
}

.js_mf .prev {
  right: 40px;
}

.js_mf .next {
  right: 5px;
}

.header_jshop_goods_filter {
  display: none;
}

.left_column .uf_options_input .uf_trackbar_inputs {
  display: flex;
}

.left_column #jshop_unijax_filter .uf_trackbar table.trackbar .c {
  background: #00ff7f;
}

.left_column .uf_options_input .uf_trackbar_inputs input,
.left_column .uf_options_input .uf_trackbar_inputs span {
  display: block !important;
  margin: 0 !important;
  width: 45% !important;
  height: 30px;
  max-width: 100%;
}

.left_column .uf_options_input .uf_trackbar_inputs span {
  width: 10% !important;
}

[class^="uf_label_"] {
  background: -moz-linear-gradient(top, #f2f5f6 0, #e3eaed 37%, #dddede 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f2f5f6), color-stop(37%, #e3eaed), color-stop(100%, #dddede));
  background: -webkit-linear-gradient(top, #f2f5f6 0, #e3eaed 37%, #dddede 100%);
  background: -o-linear-gradient(top, #f2f5f6 0, #e3eaed 37%, #dddede 100%);
  background: -ms-linear-gradient(top, #f2f5f6 0, #e3eaed 37%, #dddede 100%);
  background: linear-gradient(to bottom, #f2f5f6 0, #e3eaed 37%, #dddede 100%);
}

.left_column #jshop_unijax_filter .groupbtnleft,
.jshop_rel_prod .button_buy,
.left_column .button_detail,
#jshop_unijax_filter .groupbtnright {
  background: -webkit-linear-gradient(top, #c8d7dc, #bbb);
  box-shadow: 0 0 4px 0 #a0a0a0, inset 0 -2px 0 0 #a5a1a1 !important;
  color: #fff;
  border-radius: 7px !important;
}

.left_column #jshop_unijax_filter .groupbtnright {
  border-radius: 0 7px 7px 0 !important;
}

#DOMWindow .modal_to_cart a {
  background: #ffd700;
  border-radius: 7px !important;
  color: rgba(0, 0, 0, 0.87);
}

.button_detail:active,
#jshop_unijax_filter .groupbtnright:active,
#jshop_unijax_filter .groupbtnleft:active,
.block_product .buttons .button_buy:active,
.prod_buttons .buttons input:active,
.prod_buttons .inline_askquestion_prod:active,
.jshop_rel_prod .button_buy:active,
#DOMWindow .modal_checkout a:active,
#DOMWindow .modal_to_cart a:active {
  box-shadow: none !important;
}

.button_detail:hover,
#jshop_unijax_filter .groupbtnright:hover,
#jshop_unijax_filter .groupbtnleft:hover,
.block_product .buttons .button_buy:hover,
.prod_buttons .buttons input:hover,
.prod_buttons .inline_askquestion_prod:hover,
.jshop_rel_prod .button_buy:hover,
#DOMWindow .modal_checkout a:hover,
#DOMWindow .modal_to_cart a:hover {
  color: #fff !important;
  opacity: 0.7;
}

div.rp-filter {
  display: none !important;
}

.video_full {
  max-width: 560px;
  position: relative;
  padding-bottom: 30%;
  overflow: hidden;
}

.video_full iframe {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
}

.jshop_rel_prod .button_buy {
  color: #fff !important;
  border-radius: 7px !important;
  padding: 5px 20px;
}

.jshop_price {
  color: #333;
}

.count_block .p_m,
.count_block .p_p,
.count_block .form-control {
  float: left;
}

.count_block .p_m {
  border-radius: 10px 0 0 10px !important;
}

.count_block .p_p {
  border-radius: 0 10px 10px 0 !important;
}

.count_block .p_m,
.count_block .p_p {
  line-height: 31px;
  padding: 0 5px !important;
  box-shadow: 0 1px 0 0 #e6e6e6;
  border: 1px solid #e6e6e6 !important;
  border-bottom-color: #f7f7f7 !important;
  background: -webkit-linear-gradient(top, #fff 0, #eee 100%);
  background: linear-gradient(to bottom, #fff 0, #eee 100%);
  margin: 0;
}

[class^="icon-brooksus"]:before,
[class*=" icon-brooksus"]:before {
  vertical-align: middle;
}

.count_block .form-control {
  margin: 0;
  box-shadow: 0 1px 0 0 #e6e6e6 !important;
  border-top: 1px solid #e6e6e6 !important;
}

.count_block .p_m:active,
.count_block .p_p:active {
  position: relative;
  top: 3px;
  border-color: #e6e6e6;
  box-shadow: 0 0 20px 0 #e6e6e6 inset;
}

.count_block .p_m:focus,
.count_block .p_p:focus {
  outline: 0;
}

.rp-brooksite input.btn.list-btn.finish_registration {
  padding: 15px 30px;
  color: white;
  max-width: inherit;
  background-color: #128db1;
  text-transform: uppercase;
  margin-top: 15px;
}

#oneStepCheckoutForm div.quantity {
  display: flex;
  align-items: center;
  width: auto;
}

#oneStepCheckoutForm div.quantity span.quantitymore,
#oneStepCheckoutForm div.quantity span.quantityless {
  position: relative;
}

#oneStepCheckoutForm input[id^="quantity"],
#oneStepCheckoutForm input[id^="topquantity"] {
  width: 35px;
  height: 33px;
  text-align: center;
  box-shadow: 0 3px 0 0 #e6e6e6 !important;
  border-bottom: 0;
  border-radius: 0;
}

#oneStepCheckoutForm div.quantity span.quantitymore,
#oneStepCheckoutForm div.quantity span.quantityless {
  border: 0;
  display: block !important;
  height: 33px;
  width: 26px;
  box-shadow: 0 3px 0 0 #e6e6e6;
  border: 1px solid #e6e6e6 !important;
  border-bottom-color: #f7f7f7 !important;
  background: -webkit-linear-gradient(top, #fff 0, #eee 100%);
  background: linear-gradient(to bottom, #fff 0, #eee 100%);
}

#oneStepCheckoutForm div.quantity span.quantitymore:active,
#oneStepCheckoutForm div.quantity span.quantityless:active {
  position: relative;
  top: 3px;
  border-color: #e6e6e6;
  box-shadow: 0 0 20px 0 #e6e6e6 inset;
}

#oneStepCheckoutForm div.quantity span.quantitymore {
  border-radius: 10px 0 0 10px !important;
}

#oneStepCheckoutForm div.quantity span.quantityless {
  border-radius: 0 10px 10px 0 !important;
}

#oneStepCheckoutForm div.quantity span.quantitymore:after,
#oneStepCheckoutForm div.quantity span.quantityless:after {
  transform: translate(-50%, -50%);
  margin: 0;
  border: 0;
  color: #8b8b8b;
  font-size: 17px;
  font-family: fontawesome;
}

#oneStepCheckoutForm div.quantity span.quantitymore:after {
  content: "\f068";
}

#oneStepCheckoutForm div.quantity span.quantityless:after {
  content: "\f067";
}

.complete {
  display: block;
}

.product_caption .prod_buttons .buttons {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.prod_buttons .input-append {
  order: 3;
}

.prod_buttons .count_block {
  order: 1;
  margin-right: 1rem;
}

.prod_buttons .buttons {
  order: 2;
}

.rp-brooksite .list-btn {
  margin-bottom: 0;
}

.prod_buttons .inline_askquestion_prod {
  margin: 0;
  position: relative;
  background: #ffd700;
  border-radius: 7px !important;
  padding: 17px;
  box-shadow: 0 0 4px 0 rgba(255, 215, 0, 0.53), inset 0 -2px 0 0 #d4b40c;
}

.prod_buttons .inline_askquestion_prod i {
  display: none;
}

.prod_buttons .inline_askquestion_prod:after {
  content: "\f27a";
  position: absolute;
  font-family: fontawesome;
  top: 45%;
  transform: translate(-50%, -50%);
  left: 50%;
  color: #333;
}

.attrib .attributes_title {
  float: none;
}

.attrib .attributes_title .attributes_name {
  display: block;
  padding-bottom: 20px;
}

ul.nav.nav-tabs li {
  padding: 0;
}

.product_grid_item_container .jshop_prod_attributes select {
  max-width: 250px;
}

.product_grid_item_container .jshop_prod_attributes select.inputbox.form-control {
  width: 100%;
}

.product_grid_item_container .jshop_prod_attributes span.prod_attr_img {
  display: none;
}

.rp-brooksite .jshop_list_product .price_block {
  padding: 2px 0;
}

.product_grid_item_container .list-product-caption .buttons a {
  margin: 10px 0;
}

.productfull .tab_markup {
  margin-bottom: 20px;
}

.block_pr {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  padding: 80px 5px;
  flex-wrap: wrap;
}

.pr_secshen {
  width: 170px;
  padding: 5px;
}

.pr_secshen p {
  text-align: center;
  line-height: 120%;
  padding-top: 15px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.pr_img {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto;
}

.pr_img img {
  max-width: 96px;
}

.pr_img:hover {
  /* transform:rotateX(0deg) rotateY(360deg); */
  /* transition:transform 500ms ease 0ms */
}

.pr_secshen:hover .pr_img {
  animation: pr_anim 1800ms ease-in-out both;
  animation-iteration-count: 1;
  animation-direction: alternate;
}

#DOMWindow {
  width: auto !important;
  height: auto !important;
  width: auto !important;
  height: auto !important;
  padding: 0 !important;
  background: none !important;
  border: none !important;
}

.cont_center_column p,
.moduletable_txt_big_block p,
.moduletable.big_block p {
  padding: 10px;
  text-indent: 30px;
  text-align: justify;
}

.big_block,
.moduletable_txt_big_block,
.moduletable.big_block {
  max-width: 1200px;
  margin: 0 auto;
}

.rp-brooksite .buttons .button_buy,
.rp-brooksite form .buttons .buy,
.button_back input {
  display: inline-block;
  background: #4d5ec1;
  color: #fff;
  border-radius: 50px !important;
  padding: 0.5rem 1rem;
  position: relative;
  margin: 0 10px 0 0 !important;
}

.rp-brooksite .buttons .button_buy:hover,
.rp-brooksite form .buttons .buy:hover,
.button_back input:hover {
  color: #fff;
  background: #3f51b5;
  opacity: 1;
}

.rp-brooksite form .buttons .p_m,
.rp-brooksite form .buttons .p_p {
  border-radius: 5px;
}

.rp-brooksite .button_detail {
  border: 1px solid #f1b422;
  border-radius: 5px !important;
  display: inline-block;
}

.rp-brooksite .button_detail:hover {
  background: #f1b422;
}

.jshop.jshop_category.rp-brooksite .input-append.count_block {
  margin: 8px 0;
}

.jshop.productfull.rp-brooksite .prod_buttons .buttons input:hover {
  color: #333 !important;
}

.productfull .text_zoom img {
  float: none;
}

.productfull .text_zoom:hover {
  color: #f1b422;
}

.left_column,
.right_column {
  width: 27%;
  padding: 0 20px;
}

.cont_center_column {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
}

.jshop.address_block,
.jshop.shipping_block,
.jshop.payment_block,
.jshop.cart,
.jshop.previewfinish_block {
  justify-content: center;
}

.jshop.jshop_category.rp-brooksite {
  width: 73%;
  padding: 20px 10px;
}

.jshop.productfull.rp-brooksite {
  display: inline-block;
  max-width: 100%;
}

.productfull .image_middle {
  text-align: center;
}

.custom_maps h1,
.custom_maps h2 {
  text-align: center;
}

.block_maps {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  padding: 10px;
}

.block_maps iframe {
  max-width: 500px;
  max-height: 350px;
}

.maps_top_text {
  display: block;
  font-size: 24px;
}

.block_maps_right br {
  display: none;
}

.vm-pagination ul li a,
.vm-pagination ul li span.pagenav,
.pagenav a,
div.pagination a,
div.pagination span {
  display: inline-block;
  margin: 0 3px;
  padding: 4px 10px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}

.vm-pagination ul li a,
.pagenav a,
div.pagination a {
  border: 1px solid #d4d4d4;
  background: #fefefe;
  text-decoration: none;
  color: #27292c;
  background: -moz-linear-gradient(top, #fefefe 0, #f0f0f0 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fefefe), color-stop(100%, #f0f0f0));
  background: -webkit-linear-gradient(top, #fefefe 0, #f0f0f0 100%);
  background: -o-linear-gradient(top, #fefefe 0, #f0f0f0 100%);
  background: -ms-linear-gradient(top, #fefefe 0, #f0f0f0 100%);
  background: linear-gradient(to bottom, #fefefe 0, #f0f0f0 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fefefe',endColorstr='#f0f0f0',GradientType=0);
}

.vm-pagination ul li span.pagenav,
div.pagination span {
  border: 1px solid #ff9e34;
  color: white;
  background: #ff9e34;
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.1);
}

ul.pagenav,
div.pagination {
  margin: 10px 0 0;
  padding: 0;
}

ul.pagenav li,
div.pagination li {
  list-style: none;
  display: inline-block;
}

footer {
  background-color: rgba(0, 0, 0, 0.82);
  padding: 10px;
}

.foter-wrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  max-width: 1200px;
  padding: 20px 0;
  margin: 0 auto;
  flex-wrap: wrap;
}

.foter-wrap p,
.foter-wrap span,
.foter-wrap a,
.foter-wrap li,
.foter-wrap i {
  color: #8d8686;
}

.foter-wrap a:hover {
  color: #f5251f;
}

.foter-item {
  max-width: 250px;
  padding: 20px;
}

.footer-item-hedline {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 22px;
  display: inline-block;
  margin-bottom: 20px;
  padding-bottom: 5px;
  border-bottom: 2px solid #000cd1;
}

.foter-text {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  text-align: left;
}

.foter-item img {
  margin-bottom: 30px;
}

.foter-text i {
  font-size: 34px;
  margin-right: 10px;
}

.foter-item-top {
  border: 2px solid #fff;
}

.copyright {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  color: #8e8787;
  font-size: 13px;
  text-align: center;
  padding: 5px;
}

.moduletable_br_crmbs {
  margin: 20px;
}

.active .divider:before {
  display: none;
}

#DOMWindow {
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%);
  min-width: 320px;
  position: fixed !important;
}

.content_table {
  font-size: 0.875rem;
}

.moduletable_alarm {
  background: #ccccd1;
}

.custom_alarm {
  width: 1140px;
  max-width: 100%;
  padding: 0 15px;
  margin: 0 auto;
}

.alarm {
  font-style: normal;
  padding: 40px 0;
  font-weight: 300;
  line-height: normal;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  color: #666;
}

/*=Font size */

.ocfilter-option .ocf-option-name,
.ocfilter-option .ocf-option-values,
.ocfilter-option .ocf-option-description,
.ocfilter-option .fields-price {
  font-size: 12px;
}

.ocfilter-option .ocf-option-name {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 10px;
}

.ocfilter-option .ocf-option-values {
  overflow: visible;
}

.list-group-item {
  overflow: visible !important;
}

.ocfilter-option .ocf-option-values .dropdown-menu {
  min-width: 100%;
  max-height: 250px;
  overflow: auto;
  padding: 10px;
}

.ocfilter-option .ocf-color {
  border-radius: 3px 3px 3px 3px;
  float: left;
  height: 19px;
  margin: 1px -16px 0 -3px;
  width: 19px;
}

.ocfilter-option .ocf-image {
  background-repeat: no-repeat;
  background-position: 0 0;
  float: left;
  height: 19px;
  margin: 1px -16px 0 -3px;
  position: relative;
  width: 19px;
  z-index: 10;
}

.ocfilter-option label {
  cursor: pointer;
  display: block;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.2;
  white-space: normal;
  padding: 4px 0;
  width: 100%;
}

.ocfilter-option .dropdown-menu label {
  white-space: nowrap;
}

.ocfilter-option label input {
  display: inline-block;
  margin: 0 5px 0 0;
  vertical-align: top;
}

.ocfilter-option label small {
  background-color: #dbeaf9;
  color: #0e6a93;
  font-weight: 200;
  font-size: 10px;
  margin-left: 5px;
}

.ocfilter-option label.disabled {
  color: #999999;
}

.ocfilter-option label.disabled small {
  background-color: #efefef;
  color: #999999;
}

.ocfilter-option label {
  color: #444;
  text-decoration: none;
}

.ocfilter-option label.ocf-selected {
  color: #e56101;
}

.ocfilter-option label.ocf-selected small {
  opacity: 0;
}

/*=Collapse Options */

button[data-target="#ocfilter-hidden-options"] {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

button[data-target="#ocfilter-hidden-options"] .fa:before {
  content: "\f078";
}

button[data-target="#ocfilter-hidden-options"][aria-expanded="true"] {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

button[data-target="#ocfilter-hidden-options"][aria-expanded="true"] .fa:before {
  content: "\f077";
}

/*=Collapse Values */

.collapse-value {
  margin-bottom: 10px;
}

.collapse-value > button,
.collapse-value > button:focus,
.collapse-value > button:active,
.collapse-value > button:active:focus {
  text-align: left;
  background: transparent;
  border: none;
  box-shadow: none;
  padding: 0;
  font-size: 12px;
  text-decoration: underline;
  outline: none;
}

.collapse-value > button .fa:before {
  content: "\f107";
}

.collapse-value > button[aria-expanded="true"] .fa:before {
  content: "\f106";
}

/*=Selected Options */

.selected-options {
  color: #333333;
  padding: 0;
}

.selected-options .ocfilter-option {
  background-color: #f8f8f8;
  font-size: 10px;
  padding: 4px 5px;
}

/*=Price filtering */

#ocfilter-price .ocf-option-values {
  padding: 10px 10px 5px;
}

.trackbar,
.trackbar .left-block,
.trackbar .right-block {
  height: 4px;
}

.popover-content .form-inline {
  white-space: nowrap;
}

.popover-content .form-inline .form-group {
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
}

.scale {
  margin-bottom: 35px;
  margin-left: 15px;
  margin-right: 15px;
}

.ocfilter .popover .form-inline .form-control {
  width: 100px;
}

/* Position center style */

.ocfilter-content-top .box-content,
.ocfilter-content-bottom .box-content {
  font-size: 0;
}

.ocfilter-content-top .ocfilter-option,
.ocfilter-content-bottom .ocfilter-option {
  display: inline-block;
  vertical-align: top;
  width: 33%;
}

.ocfilter-content-top .ocfilter-option + .ocfilter-option,
.ocfilter-content-bottom .ocfilter-option + .ocfilter-option {
  border-top: none;
}

/*=Mobile */

.ocf-offcanvas {
  position: fixed;
  top: 0;
  background-color: #fff;
  bottom: 0;
  left: -250px;
  z-index: 1000;
  height: 100%;
  width: 250px;
  padding: 0;
  -webkit-transition: left 250ms ease;
  -moz-transition: left 250ms ease;
  -ms-transition: left 250ms ease;
  -o-transition: left 250ms ease;
  transition: left 250ms ease;
}

.ocf-offcanvas.active {
  left: 0;
}

.ocf-offcanvas .ocfilter-mobile-handle {
  position: absolute;
  left: 100%;
  top: 106px;
}

.ocf-offcanvas .ocfilter-mobile-handle .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ocf-offcanvas .ocf-offcanvas-body {
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 1px 0 5px rgba(0, 0, 0, 0.05);
}

#search-result {
  background-color: white;
  color: #555;
  position: absolute;
  z-index: 55;
}

table.ajaxsearch {
  border: 1px solid #ddd;
  border-collapse: collapse;
}

table.ajaxsearch td,
table.ajaxsearch tr {
  border: 0;
}

.itemsearch .itemlink {
  padding: 5px;
  display: block;
}

.itemsearch img {
  border: 0;
}

.itemsearch a:hover {
  background: 0;
  color: #095197;
}

.itemsearch:hover {
  background-color: #095197;
  color: white;
}

.itemsearch:hover span {
  color: #fff;
}

.img-block {
  display: block;
}

.detailsearch {
  display: block;
  margin: 0 10px 0 10px;
}

.jshop_ajaxsearch input,
.jshop_ajaxsearch select {
  max-width: 100%;
}

html {
  font: normal 16px/1.5 Roboto, Helvetica, Arial, Segoe UI, sans-serif;
}

.btn:focus {
  outline: 0;
  box-shadow: none;
}

a {
  transition: all 0.3s ease;
  color: #3f51b5;
}

a:hover,
a:focus {
  color: #f44336;
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

.header .collapse-search {
  overflow: visible;
}

.header__topbar {
  border-bottom: 1px solid rgba(63, 81, 181, 0.5);
}

.header__main {
  border-bottom: 1px solid rgba(63, 81, 181, 0.5);
}

.header__main .row:before {
  content: none;
}

.header__main .row > div {
  padding-left: 10px;
  padding-right: 10px;
}

.header__main .btn {
  padding: 0.375rem 0.75rem;
}

.header .logo {
  padding: 1rem 0;
}

.header .search .input-group .form-control {
  border-radius: 2rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.header .search .input-group .btn-default {
  border-radius: 2rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.header .navbar-toggle {
  margin: 0;
}

.header .navbar-contacts {
  color: #f44336;
}

.header .navbar-toggle .icon-bar {
  background-color: rgba(0, 0, 0, 0.87);
}

.header .navbar-contacts,
.header .navbar-search,
.header .navbar-toggle {
  font-size: 1.5rem;
  line-height: 1;
}

.jshop_ajaxsearch {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

#search-result {
  font-size: 0.75rem;
}

#search-result img {
  max-width: none;
  width: 36px;
  height: 36px;
}

.contact-nav > div {
  padding: 0;
}

.contact-nav .contact-item {
  margin-top: 0.375rem;
  margin-bottom: 0.375rem;
}

.contact-nav .phone,
.contact-nav .address {
  line-height: 1.5;
  font-weight: 600;
}

.contact-nav .phone .fas,
.contact-nav .address .fas {
  color: #f44336;
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.contact-nav .phone {
  font-size: 1.2rem;
}

.contact-nav .phone a {
  display: block;
}

.contact-nav .phone a:hover {
  color: #f44336;
}

.contact-nav .address {
  display: inline-flex;
  font-size: 0.875rem;
}

.contact-nav .address small {
  display: block;
  font-weight: normal;
}

.contact-nav .working-time {
  font-size: 0.75rem;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.6);
}

.contact-nav .email {
  font-size: 0.75rem;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.6);
}

.contact-nav .email a {
  color: inherit;
}

.contact-nav .email a:hover {
  color: #f44336;
}

.collapse-contacts {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.015);
}

.collapse-contacts .nav > .nav-item {
  text-align: center;
}

.cart_jsh {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
}

.cart_jsh .fas {
  font-size: 1.5rem;
}

/* product list */

.product-thumb {
  border: 1px solid #ddd;
  margin-bottom: 20px;
  overflow: auto;
}

.product-thumb .image {
  text-align: center;
}

.product-thumb .image a {
  display: block;
}

.product-thumb .image a:hover {
  opacity: 0.8;
}

.product-thumb .image img {
  margin-left: auto;
  margin-right: auto;
}

.product-grid .product-thumb .image {
  float: none;
}

.product-thumb h4 {
  font-weight: bold;
}

.product-thumb .caption {
  padding: 0 20px;
  min-height: 180px;
}

.product-list .product-thumb .caption {
  margin-left: 230px;
}

.product-thumb .rating {
  padding-bottom: 10px;
}

.rating .fa-stack {
  font-size: 8px;
}

.rating .fa-star-o {
  color: #999;
  font-size: 15px;
}

.rating .fa-star {
  color: #fc0;
  font-size: 15px;
}

.rating .fa-star + .fa-star-o {
  color: #e69500;
}

h2.price {
  margin: 0;
}

.product-thumb .price {
  color: #444;
}

.product-thumb .price-new {
  font-weight: 600;
}

.product-thumb .price-old {
  color: #999;
  text-decoration: line-through;
  margin-left: 10px;
}

.product-thumb .price-tax {
  color: #999;
  font-size: 12px;
  display: block;
}

.product-thumb .button-group {
  border-top: 1px solid #ddd;
  background-color: #eee;
  overflow: auto;
}

.product-list .product-thumb .button-group {
  border-left: 1px solid #ddd;
}

.product-thumb .button-group button {
  width: 60%;
  border: none;
  display: inline-block;
  float: left;
  background-color: #eee;
  color: #888;
  line-height: 38px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}

.product-thumb .button-group button + button {
  width: 20%;
  border-left: 1px solid #ddd;
}

.product-thumb .button-group button:hover {
  color: #444;
  background-color: #ddd;
  text-decoration: none;
  cursor: pointer;
}

.thumbnails {
  overflow: auto;
  clear: both;
  list-style: none;
  padding: 0;
  margin: 0;
}

.thumbnails > li {
  margin-left: 20px;
}

.thumbnails {
  margin-left: -20px;
}

.thumbnails > img {
  width: 100%;
}

.image-additional a {
  margin-bottom: 20px;
  padding: 5px;
  display: block;
  border: 1px solid #ddd;
}

.image-additional {
  max-width: 78px;
}

.thumbnails .image-additional {
  float: left;
  margin-left: 20px;
}

/* fixed colum left + content + right*/

/* fixed product layouts used in left and right columns */

#column-left .product-layout,
#column-right .product-layout {
  width: 100%;
}

/* fixed mobile cart quantity input */

.input-group .form-control[name^="quantity"] {
  min-width: 50px;
}

/* Missing focus and border color to overwrite bootstrap */

.btn-info:hover,
.btn-info:active,
.btn-info.active,
.btn-info.disabled,
.btn-info[disabled] {
  background-image: none;
  background-color: #df5c39;
}

/* fix oclabs */

.alert-dismissable .close,
.alert-dismissible .close {
  right: -1px;
}

.list-group-item.active {
  padding: 0.375 1rem;
  background-image: none;
  background-color: #3f51b5;
}

.list-group-item.active:focus,
.list-group-item.active:hover {
  background-image: none;
  background-color: #4d5ec1;
}

.price {
  font: normal 1.5rem/1em Roboto, Helvetica, Arial, Segoe UI, sans-serif;
  color: rgba(0, 0, 0, 0.87);
  display: inline-block;
  white-space: nowrap;
}

.price .price-new {
  white-space: nowrap;
  color: #f44336;
  display: inline-block;
}

.price .price-old {
  font: normal 0.6em/1.125em Roboto, Helvetica, Arial, Segoe UI, sans-serif;
  white-space: nowrap;
  min-height: 1.125em;
  color: rgba(0, 0, 0, 0.5);
  position: relative;
  display: block;
  text-decoration: line-through;
}

.price .price-old .currency-symbol {
  font-size: 1em;
}

.price .currency-symbol {
  font-size: 0.666em;
}

.product__price {
  display: inline-block;
  margin-right: 1rem;
  font: 600 2.5rem/1 Roboto, Helvetica, Arial, Segoe UI, sans-serif;
}

.price__value {
  font: inherit;
}

.price__symbol {
  font-size: 0.7em;
  line-height: 1;
}

.price .price_old {
  text-decoration: line-through;
  font: normal 1rem/1 Roboto, Helvetica, Arial, Segoe UI, sans-serif;
  color: rgba(0, 0, 0, 0.5);
}

.price .price_old .price__symbol {
  font-size: 1em;
  line-height: 1;
}

.price .price_special {
  color: #f44336;
}

.product-category__content {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}

.product-category__subcategories {
  order: 1;
}

.product-category__description {
  order: 10;
}

.product-category__products {
  flex: 1 1 100%;
}

.product-category hr {
  flex: 0 0 100%;
}

.row-pagination {
  flex: 1 1 100%;
}

.col-result {
  font: normal 0.875rem/1em Roboto, Helvetica, Arial, Segoe UI, sans-serif;
  text-align: right;
  margin-top: 0.5rem;
}

.col-pagination {
  text-align: center;
}

.ocfilter {
  border: none;
}

.ocfilter .list-group {
  box-shadow: none;
}

.ocfilter .list-group .list-group-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.ocfilter > .list-group .list-group-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.ocfilter > .panel-collapse > .list-group .list-group-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.ocf-offcanvas .ocfilter-mobile-handle .btn {
  border-radius: 0;
  box-shadow: 1px 0 5px rgba(0, 0, 0, 0.5);
}

.ocf-offcanvas .ocfilter-mobile-handle .btn-primary {
  background: #3f51b5;
  border-color: #3849a2;
}

.noUi-horizontal {
  height: 0.5rem !important;
}

.noUi-horizontal .noUi-handle {
  height: 1rem !important;
}

.noUi-horizontal .noUi-handle:before,
.noUi-horizontal .noUi-handle:after {
  height: 0.375rem !important;
}

.noUi-connect {
  background: #3f51b5 !important;
}

.slick-slide img {
  margin-left: auto;
  margin-right: auto;
}

.section.where-apply {
  background: rgba(0, 0, 0, 0.02);
}

.section.where-apply .section__title {
  text-align: center;
}

.apply-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}

.apply-list__item {
  transition: all 0.3s ease-in-out;
  flex: 1 1 100%;
}

.apply-list__item:hover {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.apply-list .apply-item {
  display: flex;
  background: white;
  align-items: center;
}

.apply-list .apply-item__content {
  flex: 1 1 50%;
}

.apply-list .apply-item__image {
  flex: 1 1 50%;
  max-height: 285px;
}

.apply-list .item-content {
  flex: 1 1 50%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.swiper-container {
  width: 100%;
  height: 100%;
  direction: ltr;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.jshop_register .control-label {
  white-space: nowrap;
}

.contact-phones {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 1rem;
}

.contact-phones .list-item {
  padding: 0.25rem 1rem;
}

.contact-phones .list-item a {
  font-size: 1.2rem;
}

.video-review {
  display: block;
}

.video-review__link {
  display: block;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 1.25rem;
  line-height: 3rem;
}

.video-review__link i {
  font-size: 2rem;
  line-height: inherit;
  vertical-align: middle;
}

.video-review__link span {
  line-height: inherit;
}

.video-review__link:hover i {
  color: #f44336;
}

ul.breadcrumb {
  list-style-type: none;
  padding: 0;
  margin-top: 15px;
  font-size: 0.875rem;
  background-color: transparent;
}

ul.breadcrumb li {
  display: inline-block;
  position: relative;
  padding-right: 1rem;
}

ul.breadcrumb li a {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  color: #3949ab;
}

ul.breadcrumb li a:hover {
  color: #f44336;
}

ul.breadcrumb li:before {
  content: "";
  padding: 0;
  margin: 0;
}

ul.breadcrumb li:after {
  position: absolute;
  content: "";
  right: 5px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  width: 6px;
  height: 6px;
  border-width: 1px;
  border-color: #3f51b5 #3f51b5 transparent transparent;
  border-style: solid solid none none;
}

ul.breadcrumb li:first-child {
  padding-left: 0;
}

ul.breadcrumb li:last-child {
  padding-right: 0;
}

ul.breadcrumb li:last-child:after {
  content: none;
}

.pagination {
  margin-top: 1rem;
  margin-bottom: 1rem;
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.pagination li {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.pagination li.active span,
.pagination li.dots span,
.pagination li a {
  font: normal 1.25rem/1em Roboto, Helvetica, Arial, Segoe UI, sans-serif;
  border-radius: 24px;
  padding: 0.5rem;
  display: block;
  width: 36px;
  height: 36px;
  text-align: center;
}

.pagination li.active span {
  background-color: #3f51b5;
  color: white;
  border: solid 1px rgba(0, 0, 0, 0.1);
}

.pagination li a {
  border: solid 1px rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.87);
}

.pagination li a:hover {
  border-color: #3f51b5;
  color: #3f51b5;
}

.pagination li:first-child a,
.pagination li:first-child span,
.pagination li:last-child a,
.pagination li:last-child span {
  border-radius: 24px;
}

@media (min-width: 767px) {
  .product-list .product-thumb .image {
    float: left;
    padding: 0 15px;
  }
}

@media (min-width: 768px) {
  h1,
  .h1 {
    font-size: calc(18px + 10 * (100vw / 1440));
  }

  h1,
  .h1 {
    line-height: calc(24px + 16 * (100vw / 1440));
  }

  h2,
  .h2 {
    font-size: calc(22px + 2 * (100vw / 1440));
  }

  h2,
  .h2 {
    line-height: calc(36px + 0 * (100vw / 1440));
  }

  h3,
  .h3 {
    font-size: calc(21px + 0 * (100vw / 1440));
  }

  h3,
  .h3 {
    line-height: calc(32px + 0 * (100vw / 1440));
  }

  h4,
  .h4 {
    font-size: calc(20px + 0 * (100vw / 1440));
  }

  h4,
  .h4 {
    line-height: calc(28px + 0 * (100vw / 1440));
  }

  h5,
  .h5 {
    font-size: calc(18px + 0 * (100vw / 1440));
  }

  h5,
  .h5 {
    line-height: calc(24px + 0 * (100vw / 1440));
  }

  h6,
  .h6 {
    font-size: calc(16px + 0 * (100vw / 1440));
  }

  h6,
  .h6 {
    line-height: calc(20px + 0 * (100vw / 1440));
  }

  .mainmenu {
    background-color: #3f51b5;
  }

  .mainmenu a {
    color: white;
  }

  .mainmenu .navbar-nav {
    display: flex;
  }

  .jshop.rp-brooksite h1,
  h1.jshop-product-name {
    font-size: calc(24px + 1 * (100vw / 1440));
  }

  .header__main .row {
    display: flex;
    align-items: center;
  }

  .cart_jsh .fas {
    font-size: 2rem;
  }

  #column-left .product-layout .col-md-3 {
    width: 100%;
  }

  #column-left + #content .product-layout .col-md-3 {
    width: 50%;
  }

  #column-left + #content + #column-right .product-layout .col-md-3 {
    width: 100%;
  }

  #content + #column-right .product-layout .col-md-3 {
    width: 100%;
  }

  .apply-list .item-content {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 992px) {
  .mainmenu .navbar-nav > li > a {
    padding: 0.5rem 1rem;
    color: white;
    font-size: 0.875rem;
    text-transform: uppercase;
    text-align: center;
  }

  .contact-nav {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex: 1 1 100%;
  }

  .apply-list__item {
    flex: 1 1 50%;
  }

  .apply-list__item:nth-child(4n + 1) .item-content,
  .apply-list__item:nth-child(4n + 2) .item-content {
    position: relative;
  }

  .apply-list__item:nth-child(4n + 1) .item-content:after,
  .apply-list__item:nth-child(4n + 2) .item-content:after {
    position: absolute;
    content: "";
    width: 36px;
    height: 36px;
    background: white;
    top: 50%;
    right: 0;
    transform: translateX(50%) translateY(-50%) rotate(45deg);
    z-index: 1;
  }

  .apply-list__item:nth-child(4n + 3),
  .apply-list__item:nth-child(4n + 4) {
    flex-direction: row-reverse;
  }

  .apply-list__item:nth-child(4n + 3) .item-content,
  .apply-list__item:nth-child(4n + 4) .item-content {
    position: relative;
  }

  .apply-list__item:nth-child(4n + 3) .item-content:after,
  .apply-list__item:nth-child(4n + 4) .item-content:after {
    position: absolute;
    content: "";
    width: 36px;
    height: 36px;
    background: white;
    top: 50%;
    left: 0;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    z-index: 1;
  }
}

@media (min-width: 1200px) {
  #content .col-lg-2:nth-child(6n + 1),
  #content .col-lg-3:nth-child(4n + 1),
  #content .col-lg-4:nth-child(3n + 1),
  #content .col-lg-6:nth-child(2n + 1) {
    clear: left;
  }
}

@media (min-width: 1440px) {
  h1,
  .h1 {
    font-size: 28px;
  }

  h1,
  .h1 {
    line-height: 40px;
  }

  h2,
  .h2 {
    font-size: 24px;
  }

  h2,
  .h2 {
    line-height: 36px;
  }

  h3,
  .h3 {
    font-size: 21px;
  }

  h3,
  .h3 {
    line-height: 32px;
  }

  h4,
  .h4 {
    font-size: 20px;
  }

  h4,
  .h4 {
    line-height: 28px;
  }

  h5,
  .h5 {
    font-size: 18px;
  }

  h5,
  .h5 {
    line-height: 24px;
  }

  h6,
  .h6 {
    font-size: 16px;
  }

  h6,
  .h6 {
    line-height: 20px;
  }

  .jshop.rp-brooksite h1,
  h1.jshop-product-name {
    font-size: 25px;
  }
}

@media screen and (max-width: 1200px) {
  .moduletable_logo {
    max-width: 220px;
  }

  .moduletable_search {
    max-width: 250px;
  }
}

@media (max-width: 1199px) {
  .product-grid .product-thumb .caption {
    min-height: 210px;
    padding: 0 10px;
  }

  .product-thumb .button-group button,
  .product-thumb .button-group button + button {
    width: 33.33%;
  }
}

@media screen and (max-width: 1023px) {
  .container_header_width {
    flex-wrap: wrap;
  }

  .moduletable_logo {
    width: 100%;
    max-width: 100%;
  }

  .custom_logo {
    max-width: 250px;
    margin: 0 auto;
  }

  .cart_login {
    display: none;
  }

  .top_bar {
    border-bottom: 0;
  }

  .moduletable_btn-top-menu-mobile,
  .moduletable_btn-open-menu,
  .moduletable_btn-open-phone-mobile,
  .moduletable_btn-open-serch-mobile {
    display: block;
  }

  .moduletable_btn-open-menu {
    order: 4;
  }

  .container_header_width i {
    font-size: 28px;
  }

  .moduletable_menu {
    right: 0;
  }

  .moduletable_phones {
    left: 50%;
    transform: translatex(-50%);
  }

  .moduletable_search {
    background: no-repeat;
    left: 5%;
    border: 0;
    z-index: 999999;
    padding: 0;
  }

  .menu_top_menu > li > a,
  .jscat-and-prod_categ_menu a {
    padding: 10px 15px;
    display: block;
  }

  .phone-adress-header {
    display: block;
    white-space: nowrap;
  }

  .adress-block-header {
    margin-left: 0;
  }

  .adress-block-header {
    padding-top: 20px;
  }
}

@media (max-width: 992px) {
  .cart_view .externbootstrap_wrapp.wrapp_min .extern_wrap.dblock {
    right: 0;
    left: 0;
  }

  .cart_view .externbootstrap_wrapp.wrapp_min .extern_wrap.dblock {
    right: 0;
    left: 0;
  }

  .jshop.cart-checkout.rp-brooksite .cart-table-wrap,
  .jshop.cart-checkout.rp-brooksite .cart-result-wrap {
    width: 100%;
    float: none;
  }

  .jshop.cart-checkout.rp-brooksite .cart-result-wrap {
    border: 0;
  }

  .cart-table-wrap,
  .cart-result-wrap {
    margin-top: 15px;
  }

  .cart-table-wrap {
    padding: 15px;
  }

  .cart.rp-brooksite thead {
    display: none;
  }

  .cart.rp-brooksite tbody tr,
  #jshop_menu_order tr {
    position: relative;
    display: block;
    clear: both;
    padding: 7px 0;
    border-bottom: 1px solid #dcdcdc;
  }

  #jshop_menu_order tr {
    border-bottom: 0;
  }

  .cart.rp-brooksite tbody tr:last-child {
    border: 0;
  }

  .cart.rp-brooksite tbody td,
  #jshop_menu_order td {
    display: block;
    padding: 5px;
    width: 100%;
    border-width: 0;
    text-align: center !important;
  }

  .cart.rp-brooksite td.single_price,
  .cart.rp-brooksite td.quantity {
    padding: 7px 0;
  }

  .cart.rp-brooksite td.total_price {
    padding: 7px 0;
    font-weight: 700;
  }

  .cart.rp-brooksite .remove a.button-img img {
    margin-top: 0;
    position: absolute;
    top: 5px;
    right: 5%;
  }

  .cart.rp-brooksite .weightorder,
  .cart.rp-brooksite .shippingfree {
    text-align: right;
  }

  .cart.rp-brooksite .jshop_subtotal tbody tr {
    display: table-row;
    border: 0;
  }

  .cart.rp-brooksite .jshop_subtotal tbody td {
    display: table-cell;
    text-align: right !important;
  }

  .show-ontouch.dnone {
    display: inline-block;
  }
}

@media (max-width: 991px) {
  .pagination li.active span,
  .pagination li.dots span,
  .pagination li a {
    font-size: 1.2rem;
    line-height: 1;
    width: 36px;
    height: 36px;
  }
}

@media screen and (max-width: 990px) {
  .fixed .moduletable_logo {
    display: none;
  }
}

@media (max-width: 979px) {
  .rp-brooksite .span4.header-search {
    width: 100%;
    margin: 10px auto;
  }
}

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }

  .mfp-arrow-left {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
  }

  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }

  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }

  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }

  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }

  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }

  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    top: auto;
    position: fixed;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 3px 5px;
  }

  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }

  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }

  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}

@media (max-width: 768px) {
  h1,
  .h1 {
    font-size: calc(18px + 17 * ((100vw - 320px) / 1440));
  }

  h1,
  .h1 {
    line-height: calc(24px + 27.2 * ((100vw - 320px) / 1440));
  }

  h2,
  .h2 {
    font-size: calc(22px + 3.4 * ((100vw - 320px) / 1440));
  }

  h2,
  .h2 {
    line-height: calc(36px + 0 * ((100vw - 320px) / 1440));
  }

  h3,
  .h3 {
    font-size: calc(21px + 0 * ((100vw - 320px) / 1440));
  }

  h3,
  .h3 {
    line-height: calc(32px + 0 * ((100vw - 320px) / 1440));
  }

  h4,
  .h4 {
    font-size: calc(20px + 0 * ((100vw - 320px) / 1440));
  }

  h4,
  .h4 {
    line-height: calc(28px + 0 * ((100vw - 320px) / 1440));
  }

  h5,
  .h5 {
    font-size: calc(18px + 0 * ((100vw - 320px) / 1440));
  }

  h5,
  .h5 {
    line-height: calc(24px + 0 * ((100vw - 320px) / 1440));
  }

  h6,
  .h6 {
    font-size: calc(16px + 0 * ((100vw - 320px) / 1440));
  }

  h6,
  .h6 {
    line-height: calc(20px + 0 * ((100vw - 320px) / 1440));
  }

  .externbootstrap_wrapp {
    text-align: center;
    margin: auto;
  }

  .jshop.rp-brooksite h1,
  h1.jshop-product-name {
    font-size: calc(24px + 1.7 * ((100vw - 320px) / 1440));
  }
}

@media (max-width: 767px) {
  .header .topbar__menu {
    flex-direction: column;
    align-items: stretch;
  }

  .header .topbar__menu li {
    text-align: center;
  }

  .mainmenu .navbar-collapse {
    margin-left: -15px;
    margin-right: -15px;
  }

  .mainmenu .navbar-nav {
    margin-top: 0;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.05);
  }

  .mainmenu .navbar-nav > li > a {
    text-align: center;
  }

  .cart.rp-brooksite .cart-table-wrap {
    padding: 5px;
  }

  .cart.rp-brooksite td.single_price,
  .cart.rp-brooksite td.quantity,
  .cart.rp-brooksite tbody tr,
  #jshop_menu_order tr {
    padding: 0;
  }

  .jshop_list_product .hidden_block_information {
    display: block;
  }

  .product_grid_item:hover .product_grid_item_container,
  .product_grid_item_container {
    position: relative;
    z-index: 1;
    box-shadow: none;
  }

  .rp-brooksite .header.custom {
    margin: 0 -20px;
  }

  .navbar.rp-brooksite .nav,
  .navbar.rp-brooksite .nav > li {
    float: none;
    text-align: center;
  }

  .wrap-menu.rp-brooksite {
    margin: 0 -20px 20px -20px;
  }

  .externbootstrap_wrapp {
    text-align: center;
    margin: auto;
    min-width: inherit;
  }

  body .modal-aq,
  body.bs2 .modal-aq,
  body .modal-aqf,
  body .modal-pqv {
    max-width: inherit;
    margin-left: 0;
  }

  body .modal-aqf {
    max-width: inherit;
    margin-left: 0;
  }

  .rp-brooksite .navigation.bottom-bar li {
    display: block;
    text-align: center;
    padding: 7px 0;
  }

  .navigation_div,
  .navigation_div .prev,
  .navigation_div .next {
    text-align: center;
  }

  .header__main .row > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header .logo {
    text-align: center;
  }

  .header .logo__img {
    max-width: 250px;
  }

  .header-contacts {
    display: none;
  }

  .product-list .product-thumb .caption {
    min-height: 0;
    margin-left: 0;
    padding: 0 10px;
  }

  .product-grid .product-thumb .caption {
    min-height: 0;
  }

  .product-list .product-thumb .button-group {
    border-left: none;
  }

  .product-thumb .button-group button,
  .product-thumb .button-group button + button {
    width: 33.33%;
  }

  .pagination li.active span,
  .pagination li.dots span,
  .pagination li a {
    font-size: 1rem;
    line-height: 1.5;
    width: 24px;
    height: 24px;
    padding: 0;
  }
}

@media screen and (max-width: 767px) {
  .foter-item-top {
    box-shadow: none;
    margin-top: 0;
    background: 0;
  }

  .jshop.jshop_category.rp-brooksite {
    width: 100%;
  }
}

@media (max-width: 749px) {
  .swiper-slide {
    min-height: 300px;
  }
}

@media (max-width: 575.98px) {
  .apply-list .apply-item {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .jshop.cart-checkout.rp-brooksite td.jshop_img_description_center {
    display: none !important;
  }

  .cart.rp-brooksite td.single_price,
  .cart.rp-brooksite td.quantity,
  .cart.rp-brooksite tbody tr,
  #jshop_menu_order tr {
    padding: 0;
  }

  .jshop.cart-checkout.rp-brooksite .table {
    margin-bottom: 0;
  }

  .jshop_list_product .hidden_block_information {
    display: block;
  }

  .product_grid_item:hover .product_grid_item_container,
  .product_grid_item_container {
    position: relative;
    z-index: 1;
    box-shadow: none;
  }

  .cart.rp-brooksite .pull-left.td_1 a,
  .cart.rp-brooksite .pull-right.td_2 a {
    font-size: 12px;
  }

  .rp-brooksite #list_product_image_middle .main_image {
    width: 100%;
  }

  body .modal-aq,
  body.bs2 .modal-aq {
    max-width: inherit;
    margin-left: 0;
  }

  div.modal-aq {
    position: absolute;
  }

  body .modal-aqf {
    max-width: inherit;
    margin-left: 0;
  }

  div.modal-aqf {
    position: absolute;
  }

  body .modal-pqv {
    max-width: inherit;
    margin-left: 0 !important;
  }

  .bs2 .modal-aqf {
    max-width: inherit;
    margin-left: 0;
  }

  .bs2 div.modal-aqf {
    position: absolute;
  }

  a.prev.btn.list-btn,
  a.next.btn.list-btn,
  a.prev.btn.list-btn i,
  a.next.btn.list-btn i {
    font-size: 12px;
  }
}

@media screen and (max-width: 424px) {
  .moduletable_search {
    max-width: 100%;
    left: 0;
  }

  .jshop.productfull.rp-brooksite {
    padding: 0;
  }

  h2 {
    font-size: 20px;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .header .topbar {
    margin-bottom: 20px;
  }

  .contact-nav {
    flex-direction: column;
    align-items: center;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  #content .col-md-2:nth-child(6n + 1),
  #content .col-md-3:nth-child(4n + 1),
  #content .col-md-4:nth-child(3n + 1),
  #content .col-md-6:nth-child(2n + 1) {
    clear: left;
  }

  .apply-list .item-content {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .apply-list .item-content__title {
    line-height: 1.2;
  }

  .apply-list .item-content__text {
    font: 0.875rem/1.2 Roboto, Helvetica, Arial, Segoe UI, sans-serif;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #content .col-sm-2:nth-child(6n + 1),
  #content .col-sm-3:nth-child(4n + 1),
  #content .col-sm-4:nth-child(3n + 1),
  #content .col-sm-6:nth-child(2n + 1) {
    clear: left;
  }
}